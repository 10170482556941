import {
  CloudCentralRequestReviewStatus,
  CloudCentralRequestStatus,
  CloudCentralRequestType,
} from '../../models/enums/cloud-central-request-status.enum';

export interface CloudCentralRequestResponseModel {
  requestId: number;
  deploymentScopeId: number | null;
  automationProcessId: number | null;
  deploymentExceptionId: number | null;
  status: CloudCentralRequestStatus;
  createdAt: Date;
  updatedAt: Date;
  type: CloudCentralRequestType;
}

export interface CloudCentralRequestReviewResponseModel {
  requestId: number;
  automationProcessId: number | null;
  deploymentScopeId: number | null;
  requestStatus: CloudCentralRequestStatus;
  reviewStatus: CloudCentralRequestReviewStatus;
  createdAt: Date;
  updatedAt: Date;
  type: CloudCentralRequestType;
}

export interface CloudCentralRequestReviewersResponse {
  result: CloudCentralRequestReviewerModel[];
}

export interface CloudCentralRequestReviewerModel {
  name: string;
  email: string;
  status: CloudCentralRequestReviewerStatus;
  origin: CloudCentralRequestReviewerOrigin;
  comment?: string;
}

export enum CloudCentralRequestReviewerStatus {
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export enum CloudCentralRequestReviewerOrigin {
  Application = 'Application',
  RiskManager = 'RiskManager',
  SupportGroup = 'SupportGroup',
  Platform = 'Platform',
  ManagementGroup = 'ManagementGroup',
}

export interface CloudCentralRequestReviewerTreeNode {
  name: string;
  email: string;
  status:CloudCentralRequestReviewerStatus;
  origin: CloudCentralRequestReviewerOrigin;
  comment?: string;
  displayName: string;
  children: CloudCentralRequestReviewerTreeNode[];
}