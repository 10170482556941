import { Component, Input, OnInit } from '@angular/core';
import { ApplicationTier } from '../../../../models/enums/application-tier.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'application-tier-scoping',
  templateUrl: './application-tier-scoping.component.html',
  styleUrl: './application-tier-scoping.component.scss',
})
export class ApplicationTierScopingComponent implements OnInit {
  @Input({ required: true }) formGroup: FormGroup<ApplicationTierScopeForm>;
  @Input() readOnly: boolean = false;

  applicationTiers: ApplicationTier[] = [ApplicationTier.Tier1, ApplicationTier.Tier2, ApplicationTier.Tier3];

  onApplicationTierChecked(applicationTier: ApplicationTier, change: MatCheckboxChange): void {
    if (change.checked) {
      this.formGroup.value.applicationTiers.push(applicationTier);
    } else {
      this.formGroup.value.applicationTiers.splice(this.formGroup.value.applicationTiers.indexOf(applicationTier), 1);
    }
  }

  ngOnInit(): void {}

  public static buildFormGroup(): FormGroup<ApplicationTierScopeForm> {
    return new FormGroup<ApplicationTierScopeForm>({
      applicationTiers: new FormControl<ApplicationTier[]>([]),
    });
  }
}

export interface ApplicationTierScopeForm {
  applicationTiers: FormControl<ApplicationTier[]>;
}
