<main class="container" npSetLayout [pageLayout]="PageLayout.Layout12">
  <form [formGroup]="automationProcessForm" (ngSubmit)="onSubmitAutomationProcess()">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Create New Automation Process</mat-card-title>
        <span>Use this form to register your cloud automation processes.</span>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <mat-card-title>Fill in Automation Process information</mat-card-title>
        <mat-form-field>
          <mat-label>Automation Process Name</mat-label>
          <input matInput [formControl]="automationProcessForm.controls.name" />
          @if(automationProcessForm.controls.name.hasError('required')) {
          <mat-error>Name is required.</mat-error>
          } @if(automationProcessForm.controls.name.hasError('maxlength')) {
          <mat-error>Name maximum length is {{ automationProcessForm.controls.name.errors['maxlength'].requiredLength }} characters.</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" [formControl]="automationProcessForm.controls.description"></textarea>
          @if(automationProcessForm.controls.description.hasError('required')) {
          <mat-error>Description is required.</mat-error>
          } @if(automationProcessForm.controls.description.hasError('maxlength')) {
          <mat-error>
            Description maximum length is {{ automationProcessForm.controls.description.errors['maxlength'].requiredLength }} characters.
          </mat-error>
          }
        </mat-form-field>
        <div class="automation-process-types mb-3">
          <mat-label>Automation Process Type</mat-label>
          <mat-radio-group [formControl]="automationProcessForm.controls.automationProcessType">
            <mat-radio-button [value]="automationProcesstypes.AzurePolicy">
              {{ displayAutomationProcessType(automationProcesstypes.AzurePolicy) }}
            </mat-radio-button>
            <mat-radio-button [value]="automationProcesstypes.CloudFeature">
              {{ displayAutomationProcessType(automationProcesstypes.CloudFeature) }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <mat-label>Approval Types</mat-label>
          <mat-selection-list [formControl]="automationProcessForm.controls.approvalTypes">
            @for (type of approvalTypes; track type.value){
            <mat-list-option [value]="type.value" togglePosition="before">{{ type.displayName }}</mat-list-option>
            }
          </mat-selection-list>
          @if(automationProcessForm.controls.approvalTypes.hasError('required') && automationProcessForm.controls.approvalTypes.touched) {
          <mat-error>At least one approval type is required.</mat-error>
          }
        </div>
        <div class="maintenance-schedule">
          <mat-label>Maintenance Schedule</mat-label>
          <mat-checkbox [formControl]="automationProcessForm.controls.requiresMaintenanceSchedule">Requires Schedule</mat-checkbox>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <mat-card-title>Select Azure Resource Types</mat-card-title>
        <mat-form-field>
          <mat-label>Azure Resource Types</mat-label>
          <input
            matInput
            type="text"
            [matAutocomplete]="azureResourceTypeAutocomplete"
            [formControl]="automationProcessForm.controls.resourceTypes"
          />
          <mat-autocomplete #azureResourceTypeAutocomplete="matAutocomplete" (optionSelected)="onResourceTypeSelected($event)">
            @for (resourceType of filteredResourceTypes | async; track resourceType.value) {
            <mat-option [value]="resourceType">
              <mat-icon [svgIcon]="resourceType.image"></mat-icon>
              {{ resourceType.displayName }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-listbox>
          @for (resourceType of selectedResourceTypes; track resourceType.value) {
          <mat-chip (removed)="removeResourceType(resourceType)" [disabled]="isFormSubmitting">
            <mat-icon [svgIcon]="resourceType.image"></mat-icon>
            {{ resourceType.displayName }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          }
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
    @if (automationProcessForm.value.automationProcessType === automationProcesstypes.AzurePolicy) {
    <azure-policy-editor></azure-policy-editor>
    }
    <mat-card>
      <mat-card-content>
        <mat-card-title>Select Business Capability</mat-card-title>
        @if (businessCapabilitiesSelectorIsLoading) {
        <mat-spinner [diameter]="75" class="m-3"></mat-spinner>
        } @else {
        <div class="row">
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Business Capability</mat-label>
              <input
                type="text"
                matInput
                [formControl]="automationProcessForm.controls.businessCapability"
                [matAutocomplete]="businessCapabilitiesAutocomplete"
              />
              <mat-autocomplete #businessCapabilitiesAutocomplete="matAutocomplete" [displayWith]="displayBusinessCapabilityName">
                @for (businessCapability of filteredCapabilities | async; track businessCapability) {
                <mat-option [value]="businessCapability">{{ businessCapability.name }}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Digital Platform</mat-label>
              <input matInput readonly [formControl]="automationProcessForm.controls.digitalPlatform" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Support Group</mat-label>
              <input matInput readonly [formControl]="automationProcessForm.controls.supportGroup" />
            </mat-form-field>
          </div>
        </div>
        }
      </mat-card-content>
    </mat-card>
    <div class="button-row">
      <button mat-raised-button color="primary mr-3" [disabled]="automationProcessForm.invalid || isFormSubmitting" type="submit">
        Submit Automation Process
      </button>
      <button mat-raised-button [disabled]="isFormSubmitting">Clear Form</button>
    </div>
  </form>
</main>
