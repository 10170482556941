<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scope for Application Tiers</mat-card-title>
    <label>Application Tiers:</label>
    <div class="application-tiers">
      @for (applicationTier of applicationTiers; track applicationTier) {
        <mat-checkbox
          [checked]="formGroup.value.applicationTiers.includes(applicationTier)"
          [value]="applicationTier"
          [disabled]="readOnly"
          (change)="onApplicationTierChecked(applicationTier, $event)"
        >
          {{ applicationTier }}
        </mat-checkbox>
      }
    </div>
  </mat-card-content>
</mat-card>
