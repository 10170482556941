<mat-card [formGroup]="tagFilters">
  <mat-card-content>
    <mat-card-title>Select Tag Filters</mat-card-title>
    <div class="row">
      <mat-form-field class="col-md-6">
        <mat-label>Tag Name</mat-label>
        <input matInput [formControl]="tagFilters.controls.tagName" />
        @if (tagFilters.controls.tagName.invalid) {
          <mat-error>{{ getTagFilterErrorMessage(tagFilters.controls.tagName) }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <mat-label>Tag Value</mat-label>
        <input matInput [formControl]="tagFilters.controls.tagValue" />
        @if (tagFilters.controls.tagValue.invalid) {
          <mat-error>{{ getTagFilterErrorMessage(tagFilters.controls.tagValue) }}</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="row">
      <mat-chip-listbox class="azure-tag-chip">
        @for (tag of tagFilters?.value?.tags | keyvalue; track tag.key) {
          <mat-chip (removed)="onRemoveTagFilter(tag.key)" [disabled]="readonly">
            {{ tag.key + ": " + tag.value }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        }
      </mat-chip-listbox>
      <div class="ml-auto">
        <button mat-raised-button color="primary" class="mr-3" (click)="onAddTagFilterClicked()" [disabled]="readonly || tagFilters.disabled">
          Add Tag
        </button>
        <button mat-raised-button (click)="onClearTagValuesClicked()" [disabled]="readonly || tagFilters.disabled">Clear</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
