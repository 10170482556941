export class ErrorMapper {
  public static getErrorMessage(errorCode: string): string {
    switch (errorCode) {
      case 'None':
        return '';
      case 'DeploymentExceptionExpirationMoreThanYear':
        return 'The deployment exception expiration date cannot be more than a year from now.';
      case 'DeploymentExceptionExpirationInPast':
        return 'The deployment exception expiration date cannot be in the past.';
      case 'DeploymentExceptionNotFound':
        return 'The specified deployment exception was not found.';
      case 'DeploymentScopesMoreThanOneSubscriptionSelected':
        return 'More than one subscription selected in deployment scopes.';
      case 'DeploymentScopesMoreThanOneSubscriptionGroupSelected':
        return 'More than one subscription group selected in deployment scopes.';
      case 'AutomationProcessNotFound':
        return 'The specified automation process was not found.';
      case 'ExceptionScopingIsNone':
        return 'Exception for scoping process with status None is not allowed.';
      case 'BusinessApplicationNotFound':
        return 'The specified business application was not found.';
      case 'AzureResourceNotFound':
        return 'The specified Azure resource was not found.';
      case 'DigitalPlatformNotFound':
        return 'The specified digital platform was not found.';
      case 'ApplicationTierNotFound':
        return 'The specified application tier was not found.';
      case 'ManagementGroupNotFound':
        return 'The specified management group was not found.';
      case 'DeploymentScopeNotFound':
        return 'The specified deployment scope was not found.';
      case 'DeploymentScopeNoAccess':
        return 'No access to the specified deployment scope.';
      case 'RequestReviewerNotFound':
        return 'Reviewer was not found as allowed for request.';
      case 'RequestStatusNotPending':
        return 'Attempt to change status on not pending request.';
      case 'ReviewerNotAllowed':
        return 'The reviewer is not allowed to change status on request.';
      case 'DeploymentExceptionParametersNotSet':
        return 'Deployment Exception create command properties not valid or empty.';
      case 'NotFound':
        return 'Resource not found.';
      default:
        throw new Error(`Mapping for error code '${errorCode}' is not supported.`);
    }
  }
}
