import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationTier } from '../../../models/enums/application-tier.enum';
import { ServiceNowResourcesService } from '../../../services/service-now-resources.service/service-now-resources.service';
import { ResourcesScopeForm, ResourcesScopingComponent } from './resources-scoping/resources-scoping.component';
import { IResourcesScopingModel } from '../../../services/deployment-scope/deployment-scope.model';
import { ManagementGroupForm, ManagementGroupsComponent } from './management-groups/management-groups.component';
import { ScopingType } from '../../../models/enums/scoping-type.enum';
import { DeploymentScopeDataService } from '../service/deployment-scope-data.service';
import {
  BuisnessApplicationScopingComponent,
  BusinessApplicationScopingForm,
} from './business-application-scoping/business-application-scoping.component';
import { DigitalPlatformScopeForm, DigitalPlatformScopingComponent } from './digital-platform-scoping/digital-platform-scoping.component';
import { ApplicationTierScopeForm, ApplicationTierScopingComponent } from './application-tier-scoping/application-tier-scoping.component';

@Component({
  selector: 'scoping-type',
  templateUrl: './scoping-type.component.html',
  styleUrl: './scoping-type.component.scss',
})
export class ScopingTypeComponent implements OnInit {
  deploymentScopeDataService = inject(DeploymentScopeDataService);
  scopingTypes: ScopingType[] = [];

  @Input({ required: true }) readOnly: boolean = false;
  @Input({ required: true }) formGroup: FormGroup<ScopingTypeForm>;

  snowService = inject(ServiceNowResourcesService);

  scopingTypeOptions = ScopingType;
  applicationTiers: ApplicationTier[] = [ApplicationTier.Tier1, ApplicationTier.Tier2, ApplicationTier.Tier3];

  ngOnInit(): void {
    this.deploymentScopeDataService.automationProcessMessage.subscribe((sub) => {
      if (!sub) {
        return;
      }

      this.resetScopingTypeValues();

      this.formGroup.controls.scopingTypes.setValue(sub.scopingTypes);
    });

    this.formGroup.controls.scopingType.valueChanges.subscribe((sub: ScopingType) => {
      this.resetScopingTypeValues();
    });
  }

  mapDeploymentScopeType(value: ScopingType): string {
    switch (value) {
      case ScopingType.ApplicationTier:
        return 'By Application Tier';
      case ScopingType.DigitalPlatform:
        return 'By Digital Platform';
      case ScopingType.Resources:
        return 'By Resources';
      case ScopingType.ManagementGroup:
        return 'By Management Groups';
      case ScopingType.BusinessApplication:
        return 'By Business Applications';
    }
  }

  resetScopingTypeValues(): void {
    this.formGroup.controls.digitalPlatform.patchValue({
      digitalPlatform: null,
    });

    this.formGroup.controls.resources.patchValue({
      subscriptions: [],
      resourceGroups: [],
      resources: [],
    });

    this.formGroup.controls.managementGroup.patchValue({
      managementGroups: [],
    });

    this.formGroup.controls.businessApplications.patchValue({
      selectedApplications: [],
    });
  }

  public static buildScopingType(): FormGroup<ScopingTypeForm> {
    return new FormGroup({
      scopingTypes: new FormControl<ScopingType[]>([]),
      scopingType: new FormControl<ScopingType>(null, Validators.required),
      digitalPlatform: DigitalPlatformScopingComponent.buildFormGroup(),
      resources: ResourcesScopingComponent.buildResourcesScopingForm(),
      managementGroup: ManagementGroupsComponent.buildManagementGroupForm(),
      businessApplications: BuisnessApplicationScopingComponent.buildFormGroup(),
      applicationTiers: ApplicationTierScopingComponent.buildFormGroup(),
    });
  }

  public static getScopingModels(formGroup: FormGroup<ScopingTypeForm>): [IResourcesScopingModel, number, number[], number[], ApplicationTier[]] {
    let resourcesScope: IResourcesScopingModel;
    let managementGroupIds: number[] | null;
    let businessApplicationIds: number[] | null;
    let applicationTiers: ApplicationTier[] | null;
    let digitalPlatformId: number | null;

    const scopingType = formGroup.value.scopingType;

    switch (scopingType) {
      case ScopingType.Resources:
        resourcesScope = ResourcesScopingComponent.getResourcesScopingModel(formGroup.controls.resources);
        break;

      case ScopingType.ManagementGroup:
        managementGroupIds = formGroup.value.managementGroup.managementGroups.map((x) => x.id);
        break;

      case ScopingType.DigitalPlatform:
        digitalPlatformId = formGroup.value.digitalPlatform.digitalPlatform.id;
        break;

      case ScopingType.BusinessApplication:
        businessApplicationIds = formGroup.value.businessApplications.selectedApplications.map((x) => x.id);
        break;

      case ScopingType.ApplicationTier:
        applicationTiers = formGroup.value.applicationTiers.applicationTiers;
    }

    return [resourcesScope, digitalPlatformId, managementGroupIds, businessApplicationIds, applicationTiers];
  }
}

export interface ScopingTypeForm {
  scopingTypes: FormControl<ScopingType[]>;
  scopingType: FormControl<ScopingType>;
  applicationTiers: FormGroup<ApplicationTierScopeForm>;
  digitalPlatform: FormGroup<DigitalPlatformScopeForm>;
  resources: FormGroup<ResourcesScopeForm>;
  managementGroup: FormGroup<ManagementGroupForm>;
  businessApplications: FormGroup<BusinessApplicationScopingForm>;
}
