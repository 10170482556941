<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scope for Digital Platform</mat-card-title>
    <div>
      <mat-form-field>
        <mat-label>Digital Platform</mat-label>
        <mat-select [formControl]="formGroup.controls.digitalPlatform" [compareWith]="compareFn">
          @for (digitalPlatform of digitalPlatforms; track digitalPlatform) {
            <mat-option [value]="digitalPlatform">{{ digitalPlatform.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
