<main class="container" npSetLayout [pageLayout]="PageLayout.Layout12">
  <section>
    <div class="row">
      <div class="col">
        <np-tabbed>
          <ul class="tabs">
            <li role="tab" aria-selected="true">My Requests</li>
            <li role="tab" aria-selected="false">My Reviews</li>
          </ul>
          <div class="tab-content" role="tabpanel" aria-hidden="false">
            @if (cloudCentralRequestsLoading) {
            <div class="centered-spinner mt-3">
              <mat-spinner [diameter]="150" [strokeWidth]="10" class="m-3"></mat-spinner>
            </div>
            } @else {
            <table mat-table [dataSource]="cloudCentralRequests">
              <ng-container matColumnDef="requestId">
                <th mat-header-cell *matHeaderCellDef>Request Id</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <ng-container matColumnDef="requestType">
                <th mat-header-cell *matHeaderCellDef>Request Type</th>
                <td mat-cell *matCellDef="let element">{{ mapCloudCentralRequestType(element.type) }}</td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{ element.status }}</td>
              </ng-container>
              <ng-container matColumnDef="link">
                <th mat-header-cell *matHeaderCellDef>Link</th>
                <td mat-cell *matCellDef="let element">
                  <a  target="_self" [routerLink]="handleLink(element)">Link</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>Created Date</th>
                <td mat-cell *matCellDef="let element">{{ formatDate(element.createdAt) }}</td>
              </ng-container>
              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef>Updated At</th>
                <td mat-cell *matCellDef="let element">{{ formatDate(element.updatedAt) }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['requestId', 'requestType', 'status', 'link', 'createdAt', 'updatedAt']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['requestId', 'requestType', 'status', 'link', 'createdAt', 'updatedAt'];"></tr>
            </table>
            }
          </div>
          <div class="tab-content" role="tabpanel" aria-hidden="true">
            @if (cloudCentralRequestsForReviewLoading) {
            <div class="centered-spinner mt-3">
              <mat-spinner [diameter]="150" [strokeWidth]="10" class="m-3"></mat-spinner>
            </div>
            } @else {
            <table mat-table [dataSource]="cloudCentralRequestsForReview">
              <ng-container matColumnDef="requestId">
                <th mat-header-cell *matHeaderCellDef>Request Id</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>
              <ng-container matColumnDef="requestType">
                <th mat-header-cell *matHeaderCellDef>Request Type</th>
                <td mat-cell *matCellDef="let element">{{ mapCloudCentralRequestType(element.type) }}</td>
              </ng-container>
              <ng-container matColumnDef="requestStatus">
                <th mat-header-cell *matHeaderCellDef>Request Status</th>
                <td mat-cell *matCellDef="let element">{{ element.requestStatus }}</td>
              </ng-container>
              <ng-container matColumnDef="reviewStatus">
                <th mat-header-cell *matHeaderCellDef>Review Status</th>
                <td mat-cell *matCellDef="let element">{{ element.reviewStatus }}</td>
              </ng-container>
              <ng-container matColumnDef="link">
                <th mat-header-cell *matHeaderCellDef>Link</th>
                <td mat-cell *matCellDef="let element">
                  <a  target="_self" [href]="handleLink(element)">Link</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>Created Date</th>
                <td mat-cell *matCellDef="let element">{{ formatDate(element.createdAt) }}</td>
              </ng-container>
              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef>Updated At</th>
                <td mat-cell *matCellDef="let element">{{ formatDate(element.updatedAt) }}</td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="['requestId', 'requestType', 'requestStatus', 'reviewStatus', 'link', 'createdAt', 'updatedAt']"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: ['requestId', 'requestType', 'requestStatus', 'reviewStatus', 'link', 'createdAt', 'updatedAt'];"
              ></tr>
            </table>
            }
          </div>
        </np-tabbed>
      </div>
    </div>
  </section>
</main>
