import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAutomationProcessResponse } from '../../../services/automation-process/automation-process.model';
import { AutomationProcessSelectorForm } from './automation-process-selector.component.model';
import { AutomationProcessService } from '../../../services/automation-process/automation-process.service';
import { tap } from 'rxjs';
import { ApprovalTypeMapper } from '../../../models/enums/approval-type.enum';
import { AutomationProcessTypeMapper } from '../../../models/enums/automation-process-type.enum';
import { DeploymentScopeDataService } from '../service/deployment-scope-data.service';

@Component({
  selector: 'automation-process-selector',
  templateUrl: './automation-process-selector.component.html',
})
export class AutomationProcessSelectorComponent implements OnInit {
  private automationProcessService = inject(AutomationProcessService);
  private deploymentScopeDataService = inject(DeploymentScopeDataService);

  @Input() automationProcessSelector: FormGroup<AutomationProcessSelectorForm>;
  @Input() readonly: boolean = false;

  automationProcessSelectorIsLoading: boolean = true;
  automationProcesses: IAutomationProcessResponse[];

  ngOnInit(): void {
    this.automationProcessService
      .getAutomationProcesses()
      .pipe(tap(() => (this.automationProcessSelectorIsLoading = false)))
      .subscribe((sub) => {
        this.automationProcesses = sub;
      });

    this.automationProcessSelector.controls.automationProcess.valueChanges.subscribe((value: IAutomationProcessResponse) =>
      this.onAutomationProcessChanged(value),
    );

    if (this.readonly) {
      this.automationProcessSelector.disable();
    }
  }

  onAutomationProcessChanged(value: IAutomationProcessResponse) {
    const approvalTypes = value.approvalTypes.map(ApprovalTypeMapper.toDisplayString);
    const businessCapability = value.businessCapability;

    this.automationProcessSelector.controls.description.setValue(value.description);
    this.automationProcessSelector.controls.type.setValue(AutomationProcessTypeMapper.toDisplayString(value.type));
    this.automationProcessSelector.controls.approvalTypes.setValue(approvalTypes.join(', '));
    this.automationProcessSelector.controls.businessCapability.setValue(businessCapability.name);
    this.automationProcessSelector.controls.digitalPlatform.setValue(businessCapability.digitalPlatformName);
    this.automationProcessSelector.controls.supportGroup.setValue(businessCapability.supportGroupName);

    this.deploymentScopeDataService.changeAutomationProcess(value);
  }

  compareFn(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  public static buildAutomationProcessSelectorForm(): FormGroup<AutomationProcessSelectorForm> {
    return new FormGroup<AutomationProcessSelectorForm>({
      automationProcess: new FormControl<IAutomationProcessResponse>(null, Validators.required),
      description: new FormControl(''),
      type: new FormControl(''),
      approvalTypes: new FormControl(''),
      businessCapability: new FormControl(''),
      digitalPlatform: new FormControl(''),
      supportGroup: new FormControl(''),
    });
  }
}
