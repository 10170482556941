<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scoping Types</mat-card-title>
    <div>
      <mat-selection-list [formControl]="formGroup.controls.scopingTypes">
        <mat-list-option togglePosition="before" [value]="scopingType.ApplicationTier">Application Tier</mat-list-option>
        <mat-list-option togglePosition="before" [value]="scopingType.DigitalPlatform">Digital Platform</mat-list-option>
        <mat-list-option togglePosition="before" [value]="scopingType.ManagementGroup">Management Group</mat-list-option>
        <mat-list-option togglePosition="before" [value]="scopingType.Resources">Resources</mat-list-option>
        <mat-list-option togglePosition="before" [value]="scopingType.BusinessApplication">Business Application</mat-list-option>
      </mat-selection-list>
      @if (formGroup.controls.scopingTypes.hasError("required") && formGroup.controls.scopingTypes.touched) {
        <mat-error>At least one deployment scoping type is required.</mat-error>
      }
    </div>
  </mat-card-content>
</mat-card>
