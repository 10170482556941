<mat-card [formGroup]="maintenanceSchedule">
  <mat-card-content>
    <mat-card-title>Maintenance Schedule</mat-card-title>
    <mat-form-field>
      <mat-label>Application Short Name</mat-label>
      <input matInput [formControl]="maintenanceSchedule.controls.applicationShortName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Schedule Description</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" [formControl]="maintenanceSchedule.controls.description"></textarea>
    </mat-form-field>
    @if (timeZonesLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    } @else {
      <mat-form-field>
        <mat-label>Time Zone</mat-label>
        <input type="text" matInput [formControl]="maintenanceSchedule.controls.timeZone" [matAutocomplete]="timeZonesAutocomplete" />
        <mat-autocomplete #timeZonesAutocomplete [displayWith]="displayTimeZone(timeZones)" (optionSelected)="onTimezoneSelected($event)">
          @for (timeZone of filteredTimeZones | async; track timeZone) {
            <mat-option [value]="timeZone.id">{{ timeZone.displayName }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    }
    @if (regionDisabled === false)
    {
      <mat-form-field>
        <mat-label>Region</mat-label>
        <mat-select [formControl]="maintenanceSchedule.controls.region">
              <mat-option *ngFor="let region of maintenanceSchedule.controls.availableRegions.value" [value]="region">{{ region }}</mat-option>
          </mat-select>
      </mat-form-field>
    }
    <mat-form-field>
      <mat-label>Start Time</mat-label>
      <mtx-datetimepicker #startTimePicker type="time" timeInput [twelvehour]="false" [timeInterval]="5" mode="portrait"></mtx-datetimepicker>
      <input matInput [formControl]="maintenanceSchedule.controls.startTime" [mtxDatetimepicker]="startTimePicker" />
      <mtx-datetimepicker-toggle [for]="startTimePicker" matSuffix></mtx-datetimepicker-toggle>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Duration</mat-label>
      <mtx-datetimepicker #durationPicker type="time" timeInput [twelvehour]="false" mode="portrait"></mtx-datetimepicker>
      <input matInput [formControl]="maintenanceSchedule.controls.duration" [mtxDatetimepicker]="durationPicker" [max]="durationMaxValue" />
      <mtx-datetimepicker-toggle [for]="durationPicker" matSuffix></mtx-datetimepicker-toggle>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Frequency</mat-label>
      <input matInput readonly [formControl]="maintenanceSchedule.controls.frequency" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Interval</mat-label>
      <input matInput type="number" value="1" min="1" max="3" [formControl]="maintenanceSchedule.controls.interval" />
      <mat-icon
        matSuffix
        [matTooltipPosition]="defaultTooltipPosition"
        matTooltip="Since month is the only supported frequency, the interval may only be between 1 and 3 to ensure scheduling occurs at least once a quarter."
      >
        help_outline
      </mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Week Day</mat-label>
      <mat-select [formControl]="maintenanceSchedule.controls.dayOfWeek">
        @for (dayOfWeek of daysOfWeek; track dayOfWeek) {
          <mat-option [value]="dayOfWeek">{{ dayOfWeek }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Month Occurrence</mat-label>
      <input matInput value="1" min="1" max="5" type="number" [formControl]="maintenanceSchedule.controls.monthOccurrence" />
      <mat-icon matSuffix matTooltipPosition="left" matTooltip="The occurence of the day of the week within a month to patch.">
        help_outline
      </mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Days Offset</mat-label>
      <input matInput type="number" value="0" min="0" max="6" [formControl]="maintenanceSchedule.controls.offsetInDays" />
      <mat-icon matSuffix matTooltipPosition="left" matTooltip="The number of days after the specified patch date to start patching activities.">
        help_outline
      </mat-icon>
    </mat-form-field>
  </mat-card-content>
</mat-card>
