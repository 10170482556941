import { NgModule, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MtxDatetimepicker, MtxDatetimepickerInput, MtxDatetimepickerToggle } from '@ng-matero/extensions/datetimepicker';
import { provideMomentDatetimeAdapter } from '@ng-matero/extensions-moment-adapter';
import { MatDialogActions, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';

@NgModule({
  imports: [HttpClientModule, MtxDatetimepicker, MtxDatetimepickerInput, MtxDatetimepickerToggle, MatDialogTitle, MatDialogContent, MatDialogActions],
  exports: [
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSnackBarModule,
    MatTooltipModule,
    MtxDatetimepicker,
    MtxDatetimepickerInput,
    MtxDatetimepickerToggle,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
  ],
  providers: [
    provideMomentDatetimeAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD',
        monthInput: 'MMMM',
        yearInput: 'YYYY',
        timeInput: 'HH:mm',
        datetimeInput: 'YYYY-MM-DD HH:mm',
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthInput: 'MMMM',
        yearInput: 'YYYY',
        timeInput: 'HH:mm',
        datetimeInput: 'YYYY-MM-DD HH:mm',
        monthYearLabel: 'YYYY MMMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        popupHeaderDateLabel: 'MMM DD, ddd',
      },
    }),
  ],
})
export class MaterialUIModule {
  private _iconRegistry = inject(MatIconRegistry);
  private _sanitizer = inject(DomSanitizer);

  constructor() {
    this._addAzureIcons();
  }

  private _addAzureIcons(): void {
    this._addAzureIcon('app-service', 'App-Service.svg');
    this._addAzureIcon('arc-machine', 'Arc-Machine.svg');
    this._addAzureIcon('storage-account', 'Storage-Account.svg');
    this._addAzureIcon('virtual-machine', 'Virtual-Machine.svg');
    this._addAzureIcon('virtual-network', 'Virtual-Network.svg');
    this._addAzureIcon('subscription', 'Subscription.svg');
    this._addAzureIcon('resource-group', 'Resource-Group.svg');
    this._addAzureIcon('management-group', 'Management-Group.svg');
  }

  private _addAzureIcon(iconName: string, fileName: string): void {
    const icon = `azure-${iconName}`;
    const iconLocation = `assets/icons/azure-icons/${fileName}`;

    this._iconRegistry.addSvgIcon(icon, this._sanitizer.bypassSecurityTrustResourceUrl(iconLocation));
  }
}
