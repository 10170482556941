import { Injectable, inject } from '@angular/core';
import { HttpBackend, HttpClient, HttpHandler } from '@angular/common/http';
import { IConfig } from './config.model';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: IConfig;
  private appConfigFile = 'assets/config.json';
  private httpClient: HttpClient;

  constructor(private readonly httpHandler: HttpBackend) {
    this.httpClient = new HttpClient(httpHandler);
  }

  load(): Observable<IConfig> {
    return this.httpClient.get<IConfig>(this.appConfigFile).pipe(tap((config) => (this.config = config)));
  }

  get apiBaseUrl(): string {
    return this.config?.apiBaseUrl ?? '';
  }

  get oidcScopes(): string[] {
    return this.config?.oidcScopes ?? [];
  }
}
