import { Component, Inject, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'approve-operation-dialog',
  templateUrl: 'approve-operation-dialog.html',
  styleUrls: ['approve-operation-dialog.scss'],
})
export class ApproveOperationDialog {
  dialogRef = inject(MatDialogRef<ApproveOperationDialog>);

  formGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
    emailValidation: new FormControl('', [Validators.required, emailMatchingValidator(this.data.userEmail)]),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { userEmail: string; headerText: string }) {}

  onClose(): void {
    this.dialogRef.close({ confirmed: false, comment: null });
  }

  onConfirm(): void {
    this.dialogRef.close({ confirmed: true, comment: this.formGroup.value.comment });
  }
}

export function emailMatchingValidator(email: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = control.value;
    if (input.toLowerCase() !== email.toLowerCase()) {
      return { emailDoesNotMatch: true };
    }

    return null;
  };
}
