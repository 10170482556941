import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageLayout } from '@cvx/nextpage';
import { catchError, finalize, throwError } from 'rxjs';
import { AutomationProcessService } from '../../services/automation-process/automation-process.service';
import { IAzureResourceType } from '../../models/azure-models/azure-resource-type.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApplicationTier } from '../../models/enums/application-tier.enum';
import { IDigitalPlatform } from '../../services/service-now-resources.service/service-now-resources.model';
import { IAutomationProcessResponse } from '../../services/automation-process/automation-process.model';
import { DeploymentScopesService } from '../../services/deployment-scope/deployment-scope.service';
import { DeploymentScopeResponseModel } from '../../services/deployment-scope/deployment-scope.model';
import { ToastService } from '../../services/toast/toast.service';
import { AutomationProcessSelectorComponent } from './automation-process-selector/automation-process-selector.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { ResourcesSelectorComponent } from './resources-selector/resources-selector.component';
import { ScopingTypeComponent } from './scoping-type/scoping-type.component';
import { IAzureResource, IAzureResourceGroup, IAzureSubscription } from '../../services/azure-service.service/azure-service.model';
import { MatDialog } from '@angular/material/dialog';
import { DeploymentScopeDataService } from './service/deployment-scope-data.service';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { ManagementGroupModel } from './scoping-type/management-groups/management-groups.component';
import { BusinessApplicationSelectorComponent } from '../create-automation-process/business-application-selector/business-application-selector.component';
import { ScopingType } from '../../models/enums/scoping-type.enum';
import { BusinessApplicationSearchListItem } from '../create-automation-process/business-application-selector/business-application-selector.component.model';
import { CloudCentralRequestsService } from '../../services/requests/requests.service';

export interface IApplicationTierSelection {
  name: string;
  value: ApplicationTier;
  selected: boolean;
}

@Component({
  selector: 'create-deployment-scope-view',
  templateUrl: './create-deployment-scope.view.html',
  styleUrls: ['./create-deployment-scope.view.scss'],
})
export class CreateDeploymentScopeView implements OnInit {
  private _automationProcessService = inject(AutomationProcessService);
  private deploymentScopesService = inject(DeploymentScopesService);
  private deploymentScopeDataService = inject(DeploymentScopeDataService);
  private toastService = inject(ToastService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authService = inject(CalAngularService);
  private cloudCentralRequestsService = inject(CloudCentralRequestsService);

  dialog = inject(MatDialog);

  currentUserProfile: ICvxClaimsPrincipal;

  scopingTypeOptions = ScopingType;
  readOnlyMode: boolean = false;
  currentUserInAutomationProcessReviewerList: boolean = false;

  selectedDigitalPlatforms: IDigitalPlatform[] = [];
  resourceTypes: IAzureResourceType[] = [];
  selectedApplicationTiers: ApplicationTier[] = [];
  PageLayout = PageLayout;
  formSubmitting: boolean = false;
  formSubmitted: boolean = false;

  deploymentScopeId: number;
  cloudCentralRequestId?: number;
  businessApplicationId?: number;

  deploymentScope = new FormGroup({
    automationProcess: AutomationProcessSelectorComponent.buildAutomationProcessSelectorForm(),
    tags: TagFilterComponent.buildTagFilterForm(),
    resourceTypes: ResourcesSelectorComponent.buildResourcesSelector(),
    scopingType: ScopingTypeComponent.buildScopingType(),
    businessApplication: BusinessApplicationSelectorComponent.buildFormGroup(),
  });

  onSubmitDeploymentScope(): void {
    this.formSubmitting = true;

    const resourceTypes = this.deploymentScope.value.resourceTypes.selectedTypes.map((type) => type.value);
    const automationProcess = this.deploymentScope.controls.automationProcess.controls.automationProcess?.value;
    const tags = this.deploymentScope.value.tags.tags;
    const scopingType = this.deploymentScope.value.scopingType.scopingType;

    const [resourcesScoping, digitalPlatformId, managementGroupIds, businessApplicationIds, applicationTiers] = ScopingTypeComponent.getScopingModels(
      this.deploymentScope.controls.scopingType,
    );

    this.deploymentScope.disable();

    this.deploymentScopesService
      .createDeploymentScope(
        automationProcess.id,
        resourceTypes,
        scopingType,
        tags,
        resourcesScoping,
        managementGroupIds,
        businessApplicationIds,
        digitalPlatformId,
        applicationTiers,
      )
      .pipe(
        catchError((err) => {
          this.toastService.showError('Failed to created deployment scope.');
          this.deploymentScope.enable();

          return throwError(() => err);
        }),
        finalize(() => {
          this.formSubmitting = false;
          this.formSubmitted = true;
        }),
      )
      .subscribe((sub) => {
        this.toastService.showSuccess('Deployment scope has been created!.');

        const deploymentScopeId = sub.deploymentScopeId;
        setTimeout(() => this.router.navigate([`/deployment-scopes/${deploymentScopeId}`]), 3_000);
      });
  }

  onApproveDeploymentScope(): void {
    this.cloudCentralRequestsService.approveRequest(this.currentUserProfile.userName, this.cloudCentralRequestId);
  }

  onRejectDeploymentScope(): void {
    this.cloudCentralRequestsService.rejectRequest(this.currentUserProfile.userName, this.cloudCentralRequestId);
  }

  onAutomationProcessChanged(value: IAutomationProcessResponse) {
    if (!value) {
      return;
    }

    const allResourceTypes = this._automationProcessService.getResourceTypes();
    const automationProcessResourceTypes = allResourceTypes.filter((all) => value.resourceTypes.some((type) => type === all.value));

    this.resourceTypes = automationProcessResourceTypes;
    this.deploymentScope.value.resourceTypes.selectedTypes = [];

    this.businessApplicationId = value.businessApplicationId;
  }

  automationProcessIsSelected(): boolean {
    return !!this.deploymentScope.getRawValue().automationProcess.automationProcess;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      const deploymentScopeId = params['deploymentScopeId'];

      if (deploymentScopeId) {
        this.deploymentScopeId = deploymentScopeId;
        this.readOnlyMode = true;

        this.deploymentScope.disable();

        this.deploymentScopesService.getDeploymentScope(deploymentScopeId).subscribe((sub: DeploymentScopeResponseModel) => {
          this.initializeFormValues(sub);
          this.cloudCentralRequestId = sub.cloudCentralRequestId;
        });
      }
    });

    this.deploymentScopeDataService.automationProcessMessage.subscribe((sub) => this.onAutomationProcessChanged(sub));

    this.authService.isUserSignedIn().subscribe((value: boolean) => {
      const loggedIn = value;
      if (loggedIn) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      }
    });
  }

  initializeFormValues(deploymentScope: DeploymentScopeResponseModel): void {
    const automationProces = deploymentScope.automationProcess;
    this.businessApplicationId = automationProces.businessApplicationId;

    const automationProcessValue = {
      id: automationProces.id,
      name: automationProces.name,
      description: automationProces.description,
      resourceTypes: [],
      approvalTypes: automationProces.approvalTypes,
      category: automationProces.category,
      scopingTypes: automationProces.scopingTypes,
    } as IAutomationProcessResponse;

    this.deploymentScope.controls.automationProcess.patchValue({
      automationProcess: automationProcessValue,
      description: automationProces.description,
      category: automationProces.category,
      approvalTypes: automationProces.approvalTypes.join(', '),
    });

    this.deploymentScope.controls.tags.patchValue({
      tags: deploymentScope.tags,
    });

    const allResourceTypes = this._automationProcessService.getResourceTypes();
    const selectedTypes = allResourceTypes.filter((x) => deploymentScope.resourceTypes.includes(x.value));

    this.deploymentScope.controls.resourceTypes.patchValue({
      selectedTypes: selectedTypes,
    });

    this.initializeScopingValues(deploymentScope);
  }

  initializeScopingValues(deploymentScope: DeploymentScopeResponseModel): void {
    const scoping = this.deploymentScope.controls.scopingType;
    scoping.controls.scopingTypes.setValue(deploymentScope.automationProcess.scopingTypes);

    if (deploymentScope.resources.length > 0) {
      const subscriptions = deploymentScope.resources
        .filter((x) => x.resourceType === 'Microsoft.Resources/subscriptions')
        .map((x) => {
          return {
            displayName: x.name,
            id: x.name,
            resourceId: x.id,
          } as IAzureSubscription;
        });

      const resourceGroups = deploymentScope.resources
        .filter((x) => x.resourceType === 'Microsoft.Resources/resourceGroups')
        .map((x) => {
          return {
            resourceId: x.id,
            name: x.name,
            tags: {}, // TODO: skipping tags for now here, maybe we won't even need them in read only view.
          } as IAzureResourceGroup;
        });

      const resources = deploymentScope.resources
        .filter((x) => x.resourceType != 'Microsoft.Resources/subscriptions' && x.resourceType != 'Microsoft.Resources/resourceGroups')
        .map((x) => {
          return {
            id: x.id,
            name: x.name,
            type: x.resourceType,
            tags: {}, // TODO: skipping tags for now here, maybe we won't even need them in read only view.
          } as IAzureResource;
        });

      scoping.controls.scopingType.setValue(ScopingType.Resources);
      scoping.controls.resources.patchValue({
        subscriptions: subscriptions,
        resourceGroups: resourceGroups,
        resources: resources,
      });
    } else if (deploymentScope.digitalPlatform) {
      const digitalPlatform = {
        id: deploymentScope.digitalPlatform.id,
        name: deploymentScope.digitalPlatform.name,
        shortName: deploymentScope.digitalPlatform.name,
      } as IDigitalPlatform;

      scoping.controls.scopingType.setValue(ScopingType.DigitalPlatform);
      scoping.controls.digitalPlatform.patchValue({
        digitalPlatform: digitalPlatform,
      });
    } else if (deploymentScope.managementGroups) {
      const managementGroups = deploymentScope.managementGroups.map((x) => {
        return {
          id: x.id,
          resourceId: x.resourceId,
          displayName: x.displayName,
          name: x.name,
        } as ManagementGroupModel;
      });
      scoping.controls.scopingType.setValue(ScopingType.ManagementGroup);
      scoping.controls.managementGroup.patchValue({
        managementGroups: managementGroups,
      });
    } else if (deploymentScope.businessApplications) {
      const businessApplications = deploymentScope.businessApplications.map((x) => {
        return {
          id: x.id,
          serviceId: x.serviceId,
          name: x.name,
          shortName: x.shortName,
          technicalOwner: x.technicalOwner,
        } as BusinessApplicationSearchListItem;
      });

      scoping.controls.scopingType.setValue(ScopingType.BusinessApplication);
      scoping.controls.businessApplications.controls.selectedApplications.setValue(businessApplications);
    } else if (deploymentScope.applicationTiers) {
      scoping.controls.applicationTiers.controls.applicationTiers.setValue(deploymentScope.applicationTiers);
      scoping.controls.scopingType.setValue(ScopingType.ApplicationTier);
    }
  }
}
