import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAutomationProcessResponse } from '../../../services/automation-process/automation-process.model';
import { AutomationProcessSelectorForm } from './automation-process-selector.component.model';
import { AutomationProcessService } from '../../../services/automation-process/automation-process.service';
import { tap } from 'rxjs';
import { ApprovalTypeMapper } from '../../../models/enums/approval-type.enum';
import { DeploymentScopeDataService } from '../service/deployment-scope-data.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'automation-process-selector',
  templateUrl: './automation-process-selector.component.html',
})
export class AutomationProcessSelectorComponent implements OnInit {
  private automationProcessService = inject(AutomationProcessService);
  private deploymentScopeDataService = inject(DeploymentScopeDataService);

  @Input() automationProcessSelector: FormGroup<AutomationProcessSelectorForm>;
  @Input() readonly: boolean = false;

  automationProcessSelectorIsLoading: boolean = true;
  automationProcesses: IAutomationProcessResponse[];

  ngOnInit(): void {
    this.automationProcessService
      .getAutomationProcesses()
      .pipe(tap(() => (this.automationProcessSelectorIsLoading = false)))
      .subscribe((sub) => {
        this.automationProcesses = sub;
      });

    if (this.readonly) {
      this.automationProcessSelector.disable();
    }
  }

  onAutomationProcessChanged(event: MatSelectChange) {
    const value = event.value as IAutomationProcessResponse;

    const approvalTypes = value.approvalTypes.map(ApprovalTypeMapper.toDisplayString);

    this.automationProcessSelector.controls.description.setValue(value.description);
    this.automationProcessSelector.controls.category.setValue(value.category);
    this.automationProcessSelector.controls.approvalTypes.setValue(approvalTypes.join(', '));

    this.deploymentScopeDataService.changeAutomationProcess(value);
  }

  compareFn(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  public static buildAutomationProcessSelectorForm(): FormGroup<AutomationProcessSelectorForm> {
    return new FormGroup<AutomationProcessSelectorForm>({
      automationProcess: new FormControl<IAutomationProcessResponse>(null, Validators.required),
      description: new FormControl(''),
      category: new FormControl('', Validators.required),
      approvalTypes: new FormControl(''),
    });
  }
}
