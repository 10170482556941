import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, map, startWith, tap } from 'rxjs';
import { IAzureResource, IAzureResourceGroup, IAzureSubscription } from '../../../../services/azure-service.service/azure-service.model';
import { AzureService } from '../../../../services/azure-service.service/azure-service.service';
import { IResourcesScopingModel } from '../../../../services/deployment-scope/deployment-scope.model';
import { DeploymentScopeDataService } from '../../service/deployment-scope-data.service';
import { IAzureResourceType } from '../../../../models/azure-models/azure-resource-type.model';

@Component({
  selector: 'resources-scoping',
  templateUrl: './resources-scoping.component.html',
})
export class ResourcesScopingComponent implements OnInit, OnDestroy {
  private _azureService = inject(AzureService);
  private _tags: Record<string, string>;
  private _resourceTypes: string[] = [];
  dataService = inject(DeploymentScopeDataService);
  tagsSubscription: Subscription;
  resourceTypesSubscription: Subscription;

  @Input({ required: true }) readonly: boolean = false;
  @Input({ required: true }) formGroup: FormGroup<ResourcesScopeForm>;

  subscriptions: IAzureSubscription[] = [];
  resourceGroups: IAzureResourceGroup[] = [];
  resources: IAzureResource[] = [];

  filteredResourceGroups: Observable<IAzureResourceGroup[]>;
  filteredSubscriptions: Observable<IAzureSubscription[]>;
  filteredResources: Observable<IAzureResource[]>;

  subscriptionSelector = new FormControl();
  resourceGroupsSelector = new FormControl();
  resourcesSelector = new FormControl();

  subscriptionsSelectorIsLoading: boolean = false;
  resourceGroupsSelectorIsLoading: boolean = false;
  resourcesSelectorIsLoading: boolean = false;

  onSubscriptionSelected(event: any): void {
    const selectedSubscription = event.option.value as IAzureSubscription;
    if (this.formGroup.value.subscriptions.includes(selectedSubscription)) {
      return;
    }

    let subscriptions = this.formGroup.value.subscriptions;
    subscriptions.push(selectedSubscription);

    this.formGroup.controls.subscriptions.setValue(subscriptions);
    this.subscriptionSelector.setValue('');

    this.initializeResourceGroupsOptions();
  }

  onResourceGroupSelected(event: any): void {
    const resourceGroup = event.option.value as IAzureResourceGroup;
    if (this.formGroup.value.resourceGroups.includes(resourceGroup)) {
      return;
    }

    let resourceGroups = this.formGroup.value.resourceGroups;
    resourceGroups.push(resourceGroup);

    this.formGroup.controls.resourceGroups.setValue(resourceGroups);
    this.resourceGroupsSelector.setValue('');

    this.initializeResourceOptions();
  }

  onResourceSelected(event: any): void {
    const selectedResource = event.option.value as IAzureResource;
    if (this.formGroup.value.resources.includes(selectedResource)) {
      return;
    }

    let resources = this.formGroup.value.resources;
    resources.push(selectedResource);

    this.formGroup.controls.resources.setValue(resources);
    this.resourcesSelector.setValue('');
  }

  removeResourceGroup(resourceGroup: IAzureResourceGroup): void {
    const index = this.formGroup.value.resourceGroups.indexOf(resourceGroup);
    if (index >= 0) {
      this.formGroup.value.resourceGroups.splice(index, 1);
    }

    this.filteredResourceGroups = this.resourceGroupsSelector.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResourceGroups(value)),
    );

    this.initializeResourceOptions();
  }

  removeResource(resource: IAzureResource): void {
    const index = this.formGroup.value.resources.indexOf(resource);
    if (index >= 0) {
      this.formGroup.value.resources.splice(index, 1);
    }

    this.filteredResources = this.resourcesSelector.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResources(value)),
    );
  }

  removeSubscription(subscription: IAzureSubscription): void {
    const index = this.formGroup.value.subscriptions.indexOf(subscription);
    if (index >= 0) {
      this.formGroup.value.subscriptions.splice(index, 1);
    }

    this.filteredSubscriptions = this.subscriptionSelector.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSubscriptions(value)),
    );

    this.initializeResourceGroupsOptions();
  }

  initializeResourceGroupsOptions(): void {
    this.resourceGroups = [];
    this.formGroup.value.resourceGroups = [];
    this.resources = [];
    this.formGroup.value.resources = [];

    if (this.formGroup.value.subscriptions.length !== 1) {
      return;
    }

    this.resourceGroupsSelectorIsLoading = true;
    const subscription = this.formGroup.value.subscriptions[0];

    this._azureService
      .getResourceGroups(subscription.id, this._tags)
      .pipe(tap(() => (this.resourceGroupsSelectorIsLoading = false)))
      .subscribe((resourceGroups: IAzureResourceGroup[]) => {
        this.resourceGroups = resourceGroups;

        this.filteredResourceGroups = this.resourceGroupsSelector.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterResourceGroups(value)),
        );
      });
  }

  private initializeResourceOptions(): void {
    this.resources = [];
    this.formGroup.value.resources = [];

    if (this.formGroup.value.subscriptions.length !== 1 || this.formGroup.value.resourceGroups.length !== 1) {
      return;
    }

    this.resourcesSelectorIsLoading = true;
    const subscription = this.formGroup.value.subscriptions[0];
    const resourceGroup = this.formGroup.value.resourceGroups[0];

    this._azureService.getResources(subscription.id, resourceGroup.name, this._resourceTypes, this._tags).subscribe((resources: IAzureResource[]) => {
      this.resources = resources;

      this.filteredResources = this.resourcesSelector.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterResources(value)),
      );

      this.resourcesSelectorIsLoading = false;
    });
  }

  showResouceGroupsSelection(): boolean {
    return this.formGroup.value.subscriptions.length === 1 && this.resourceGroupsSelectorIsLoading === false;
  }

  showResourcesSelection(): boolean {
    return this.showResouceGroupsSelection() && this.formGroup.value.resourceGroups.length === 1 && this.resourcesSelectorIsLoading === false;
  }

  private _filterResourceGroups(value: string): IAzureResourceGroup[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const remainingElements = this.resourceGroups.filter((filterValue) => this.formGroup.value.resourceGroups.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingElements.filter(
      (filterValue) =>
        filterValue.name.toLowerCase().search(searchValue) > -1 ||
        Object.entries(filterValue.tags).find(([_, value]) => value.toLowerCase().search(searchValue) > -1),
    );
  }

  private _filterSubscriptions(value: string): IAzureSubscription[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }

    const remainingElements = this.subscriptions.filter((filterValue) => this.formGroup.value.subscriptions.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingElements.filter(
      (filterValue) => filterValue.displayName.toLowerCase().search(searchValue) > -1 || filterValue.id.toLowerCase().search(searchValue) > -1,
    );
  }

  private _filterResources(value: string): IAzureResource[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const remainingElements = this.resources.filter((filterValue) => this.formGroup.value.resources.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingElements.filter((filterValue) => filterValue.name.toLowerCase().search(searchValue) > -1);
  }

  ngOnInit(): void {
    this.subscriptionsSelectorIsLoading = true;

    this.tagsSubscription = this.dataService.tagsMessage.subscribe((sub: Record<string, string>) => {
      this._tags = sub;

      this.initializeResourceGroupsOptions();
    });

    this.resourceTypesSubscription = this.dataService.resourceTypesMessage.subscribe((sub: IAzureResourceType[]) => {
      const mappedTypes = sub.map((x) => x.value);
      this._resourceTypes = mappedTypes;

      this.initializeResourceOptions();
    });

    this._azureService
      .getSubscriptions()
      .pipe(tap(() => (this.subscriptionsSelectorIsLoading = false)))
      .subscribe((sub) => {
        this.subscriptions = sub;

        this.filteredSubscriptions = this.subscriptionSelector.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterSubscriptions(value)),
        );
      });

    if (this.readonly) {
      this.subscriptionSelector.disable();
      this.resourceGroupsSelector.disable();
      this.resourcesSelector.disable();
    }
  }

  ngOnDestroy(): void {
    this.tagsSubscription.unsubscribe();
    this.resourceTypesSubscription.unsubscribe();
  }

  public static buildResourcesScopingForm(): FormGroup<ResourcesScopeForm> {
    return new FormGroup<ResourcesScopeForm>({
      subscriptions: new FormControl<IAzureSubscription[]>([], Validators.required),
      resourceGroups: new FormControl<IAzureResourceGroup[]>([]),
      resources: new FormControl<IAzureResource[]>([]),
    });
  }

  public static getResourcesScopingModel(formGroup: FormGroup<ResourcesScopeForm>): IResourcesScopingModel {
    return {
      resources: formGroup.value.resources.map((x) => x.id),
      resourceGroups: formGroup.value.resourceGroups.map((x) => x.resourceId),
      subscriptions: formGroup.value.subscriptions.map((x) => x.resourceId),
    } as IResourcesScopingModel;
  }
}

export interface ResourcesScopeForm {
  subscriptions: FormControl<IAzureSubscription[]>;
  resourceGroups: FormControl<IAzureResourceGroup[]>;
  resources: FormControl<IAzureResource[]>;
}
