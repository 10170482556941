import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigService } from '../config/config.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CloudCentralRequestResponseModel, CloudCentralRequestReviewResponseModel, CloudCentralRequestReviewerModel, CloudCentralRequestReviewersResponse } from './requests.model';
import { MatDialog } from '@angular/material/dialog';
import { ApproveOperationDialog } from '../../views/create-deployment-scope/dialogs/approve-operation/approve-operation-dialog';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class CloudCentralRequestsService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;
  private approvalControllerUrl = `${this.baseUrl}/requests`;
  
  public getMyRequests(): Observable<CloudCentralRequestResponseModel[]> {
    return this.httpClient.get<CloudCentralRequestResponseModel[]>(this.approvalControllerUrl);
  }

  public getMyRequestsToReview(): Observable<CloudCentralRequestReviewResponseModel[]> {
    return this.httpClient.get<CloudCentralRequestReviewResponseModel[]>(`${this.approvalControllerUrl}/reviews`);
  }

  private dialog = inject(MatDialog);
  private toastService = inject(ToastService);

  public getReviewers(id: number): Observable<CloudCentralRequestReviewerModel[]> {
    const url = `${this.approvalControllerUrl}/${id}/reviewers`;
    return this.httpClient.get<CloudCentralRequestReviewersResponse>(url).pipe(map((data: CloudCentralRequestReviewersResponse) => data.result));
  }

  approveRequest(userName: string, id: number): void {
    const dialogRef = this.dialog.open(ApproveOperationDialog, {
      data: {
        userEmail: userName,
        headerText: 'Approve Cloud Central Request',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result.confirmed) {
        return;
      }

      this.onApproveRequest(id, result.comment)
        .subscribe(() => {
          this.toastService.showSuccess('Succesfully Approved Cloud Central Request.');

          setTimeout(function () {
            window.location.reload();
          }, 2500);
        });
    });
  }

  rejectRequest(userName: string, id: number): void {
    const dialogRef = this.dialog.open(ApproveOperationDialog, {
      data: {
        userEmail: userName,
        headerText: 'Reject Cloud Central Request.',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirmed) {
        this.onRejectRequest(id, result.comment)
          .subscribe((sub) => {
            this.toastService.showSuccess('Succesfully Rejected Cloud Central Request!');

            setTimeout(function () {
              window.location.reload();
            }, 2500);
          });
      }
    });
  }

  private onRejectRequest(id: number, comment: string): Observable<Object> {
    const url = `${this.approvalControllerUrl}/${id}/reject`;
    return this.httpClient.post(url, { comment: comment });
  }

  private onApproveRequest(id: number, comment: string): Observable<Object> {
    const url = `${this.approvalControllerUrl}/${id}/approve`;
    return this.httpClient.post(url, { comment: comment });
  }
}
