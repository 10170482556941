import { Component, Input, OnInit } from '@angular/core';
import { ScopingType } from '../../../models/enums/scoping-type.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'scoping-type-selector',
  templateUrl: './scoping-type-selector.component.html',
})
export class ScopingTypeSelectorComponent implements OnInit {
  scopingType = ScopingType;

  @Input({ required: true }) formGroup: FormGroup<ScopingTypeSelectorForm>;
  ngOnInit(): void {}

  public static buildFormGroup(): FormGroup<ScopingTypeSelectorForm> {
    return new FormGroup<ScopingTypeSelectorForm>({
      scopingTypes: new FormControl<ScopingType[]>([], Validators.required),
    });
  }
}

export interface ScopingTypeSelectorForm {
  scopingTypes: FormControl<ScopingType[]>;
}
