<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scope for Management Group</mat-card-title>
    @if (managementGroupsSelectorIsLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    } @else {
      <mat-form-field>
        <mat-label>Management Groups</mat-label>
        <input matInput type="text" [matAutocomplete]="managementGroupsAutocomplete" [formControl]="managementGroupSelector" />
        <mat-autocomplete
          #managementGroupsAutocomplete="matAutocomplete"
          [displayWith]="displayManagementGroupName"
          (optionSelected)="onManagementGroupSelected($event)"
        >
          @for (managementGroup of filteredManagementGroups | async; track managementGroup.resourceId) {
            <mat-option [value]="managementGroup">
              <mat-icon svgIcon="azure-management-group"></mat-icon>{{ managementGroup.displayName }} - {{ managementGroup.name }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <mat-chip-listbox>
        @for (managementGroup of formGroup.value.managementGroups; track managementGroup.id) {
          <mat-chip [disabled]="readonly" (removed)="onRemoveManagementGroup(managementGroup)">
            <mat-icon svgIcon="azure-management-group"></mat-icon>
            {{ managementGroup.displayName }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        }
      </mat-chip-listbox>
    }
    @if (dataSource.data.length > 0) {
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef>
            <span [style.paddingLeft.px]="40">Name</span>
          </th>
          <td mat-cell *matCellDef="let data">
            <span class="name-container">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 32"
                (click)="treeControl.toggle(data)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(data) ? "expand_more" : "chevron_right" }}
                </mat-icon>
              </button>
              <mat-icon [svgIcon]="mapManagementGroupTypeIcon(data.type)"> </mat-icon>
              <span [style.paddingLeft.px]="5">
                {{ data.displayName }}
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            <span>Type</span>
          </th>
          <td mat-cell *matCellDef="let data">
            {{ mapManagementGroupTypeDisplayName(data.type) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span>ID</span>
          </th>
          <td mat-cell *matCellDef="let data">
            {{ data.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalSubscriptions">
          <th mat-header-cell *matHeaderCellDef>
            <span>Total subscriptions</span>
          </th>
          <td mat-cell *matCellDef="let data">
            {{ displayTotalSubscriptions(data) }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    }
  </mat-card-content>
</mat-card>
