import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceNowResourcesService } from '../../../../services/service-now-resources.service/service-now-resources.service';
import { IDigitalPlatform } from '../../../../services/service-now-resources.service/service-now-resources.model';

@Component({
  selector: 'digital-platform-scoping',
  templateUrl: './digital-platform-scoping.component.html',
})
export class DigitalPlatformScopingComponent implements OnInit {
  snowService = inject(ServiceNowResourcesService);

  @Input({ required: true }) formGroup: FormGroup<DigitalPlatformScopeForm>;
  @Input() readOnly: boolean = false;

  digitalPlatforms: IDigitalPlatform[] = [];

  ngOnInit(): void {
    this.snowService.getDigitalPlatforms().subscribe((sub) => {
      this.digitalPlatforms = sub;
    });
  }

  compareFn(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  public static buildFormGroup(): FormGroup<DigitalPlatformScopeForm> {
    return new FormGroup<DigitalPlatformScopeForm>({
      digitalPlatform: new FormControl<IDigitalPlatform>(null, Validators.required),
    });
  }
}

export interface DigitalPlatformScopeForm {
  digitalPlatform: FormControl<IDigitalPlatform>;
}
