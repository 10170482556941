import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigService } from '../config/config.service';
import { map, Observable, of } from 'rxjs';
import { IDeploymentExceptionRequestModel, IDeploymentExceptionResponseModel, IGetDeploymentExceptionResponseModel } from './deployment-exception.model';
import { ExceptionScopingType } from '../../models/enums/scoping-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DeploymentExceptionService {

  private httpClient = inject(HttpClient);
  private config = inject(AppConfigService);
  private baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getExceptionTypes(): Observable<ExceptionScopingType[]> {
    return of([
      ExceptionScopingType.BusinessApplication,
      ExceptionScopingType.DigitalPlatform,
      ExceptionScopingType.ApplicationTier,
    ]);
  }

  public createDeploymentException(request: IDeploymentExceptionRequestModel): Observable<IDeploymentExceptionResponseModel> {
    return this.httpClient.post<IDeploymentExceptionResponseModel>(`${this.baseUrl}/deployment-exceptions`, request);
  }

  public getDeploymentException(deploymentExceptionId: number) : Observable<IGetDeploymentExceptionResponseModel>{
    return this.httpClient.get<IGetDeploymentExceptionResponseModel>(`${this.baseUrl}/deployment-exceptions/${deploymentExceptionId}`);
  }
}
