export enum OperatingSystemType {
  Windows = 'Windows',
  Linux = 'Linux',
  All = 'All',
}

export class OperatingSystemTypeMapper {
  static toDisplayString(type: OperatingSystemType): string {
    switch (type) {
      case OperatingSystemType.Windows:
        return 'Windows';
      case OperatingSystemType.Linux:
        return 'Linux';
      case OperatingSystemType.All:
        return 'All';
      default:
        throw new Error('Invalid OperatingSystemType');
    }
  }
}
