import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { IExpiresAtExceptionDateTimeFormGroup } from '../../services/deployment-exception/deployment-exception.model';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, MatCardModule, MatInputModule, MatIconModule],
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit {
  @Input({ required: true }) formGroup!: FormGroup<IExpiresAtExceptionDateTimeFormGroup>;
  @Input() title: string;
  @Input() isReadOnly: boolean;

  ngOnInit(): void {}

  public static buildExpiresAtFormGroup(defaultDate: Date, validators: ValidatorFn): FormGroup<IExpiresAtExceptionDateTimeFormGroup> {
    return new FormGroup({
      date: new FormControl(defaultDate, [Validators.required, validators]),
    });
  }
}
