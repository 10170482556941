<main class="container" npSetLayout [pageLayout]="PageLayout.Layout12">
  <form [formGroup]="deploymentScope">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Create New Deployment Scope</mat-card-title>
        <span>Use this form to create a new deployment scope for opting in or opting out Azure resources from cloud offerings.</span>
      </mat-card-content>
    </mat-card>
    <automation-process-selector [automationProcessSelector]="deploymentScope.controls.automationProcess"></automation-process-selector>
    @if (automationProcessIsSelected()) {
    <mat-card>
      <mat-card-content>
        <mat-card-title>Deployment Scope Inclusion/Exclusion</mat-card-title>
        <mat-radio-group [formControl]="deploymentScope.controls.inclusion" class="options-selector">
          <mat-radio-button [value]="inclusionTypeOptions.Included">Included</mat-radio-button>
          <mat-radio-button [value]="inclusionTypeOptions.Excluded">Excluded</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
    } @if (deploymentScope.value?.automationProcess?.automationProcess?.requiresMaintenanceSchedule) {
    <maintenance-schedule [maintenanceSchedule]="deploymentScope.controls.maintenanceSchedule" [readonly]="readOnlyMode" />
    } @if (automationProcessIsSelected()) {
    <resources-selector [formGroup]="deploymentScope.controls.resourceTypes" [readOnly]="readOnlyMode"></resources-selector>
    } @if (automationProcessIsSelected()) {
    <tag-filter [tagFilters]="deploymentScope.controls.tags" [readonly]="readOnlyMode"></tag-filter>
    } @if (automationProcessIsSelected()) {
    <scoping-type #scopingType [scopingTypeGroup]="deploymentScope.controls.scopingType" [readOnly]="readOnlyMode"></scoping-type>
    } @if (readOnlyMode) {
    <reviewers-list [deploymentScopeId]="deploymentScopeId"></reviewers-list>
    } @if (readOnlyMode) {
    <div class="button-row">
      <button mat-raised-button color="primary" class="mr-3" (click)="onApproveDeploymentScope()">Approve Request</button>
      <button mat-raised-button color="warn" class="mr-3" (click)="onRejectDeploymentScope()">Reject Request</button>
    </div>
    } @else {
    <div class="form-footer button-row">
      <button mat-raised-button color="primary" class="mr-3" (click)="onSubmitDeploymentScope()">Submit Deployment Scope</button>
      <button mat-raised-button>Cancel</button>
    </div>
    }
  </form>
</main>
