import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NextPageRoute } from '@cvx/nextpage';
import { NextPageService } from '@cvx/nextpage';
import { HomeView } from './shared/views/home-view/home.view';
import { CreateDeploymentScopeView } from './shared/views/create-deployment-scope/create-deployment-scope.view';
import { MyProfileView } from './shared/views/my-profile/my-profile.view';
import { CreateAutomationProcessView } from './shared/views/create-automation-process/create-automation-process.view';
import { CalGuardService } from '@cvx/cal-angular';
import { CreateDeploymentExceptionView } from './shared/views/create-deployment-exception/create-deployment-exception.view';

export const routes: NextPageRoute[] = [
  {
    path: '',
    component: HomeView,
    pathMatch: 'full',
    canActivate: [CalGuardService],
  },
  {
    path: 'deployment-scopes',
    component: CreateDeploymentScopeView,
    pathMatch: 'full',
    canActivate: [CalGuardService],
  },
  {
    path: 'deployment-exception',
    component: CreateDeploymentExceptionView,
    pathMatch: 'full',
    canActivate: [CalGuardService],
  },
  {
    path: 'deployment-exception/:deploymentExceptionId',
    component: CreateDeploymentExceptionView,
    pathMatch: 'full',
    canActivate: [CalGuardService],
  },
  {
    path: 'deployment-scopes/:deploymentScopeId',
    component: CreateDeploymentScopeView,
    canActivate: [CalGuardService],
  },
  {
    path: 'automation-process',
    component: CreateAutomationProcessView,
    pathMatch: 'full',
  },
  {
    path: 'my-profile',
    component: MyProfileView,
    canActivate: [CalGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private NP: NextPageService) {
    this.NP.options.navigationRoutes = routes;
  }
}
