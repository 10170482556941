<mat-card>
  <mat-card-content>
    <mat-card-title>Select Exception Scoping Type</mat-card-title>
    <div>
      <mat-selection-list [formControl]="formGroup.controls.exceptionScopingTypes">
        <mat-list-option togglePosition="before" [value]="exceptionScopingType.BusinessApplication">Business Application</mat-list-option>
        <mat-list-option togglePosition="before" [value]="exceptionScopingType.DigitalPlatform">Digital Platform</mat-list-option>
        <mat-list-option togglePosition="before" [value]="exceptionScopingType.ApplicationTier">Application Tier</mat-list-option>
      </mat-selection-list>
      @if (formGroup.controls.exceptionScopingTypes.hasError("required") && formGroup.controls.exceptionScopingTypes.touched) {
        <mat-error>At least one exception scoping type is required.</mat-error>
      }
    </div>
  </mat-card-content>
</mat-card>
