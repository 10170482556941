export class AutomationProcessNameSanitizer {
  public static sanitize(displayName: string): string {
    let result = displayName.toLowerCase();

    result = this.removeSpecialCharacters(result);
    result = result.trimEnd();
    result = result.replace(/\s+/g, '-');

    return result;
  }

  public static isNameValid(automationProcessName: string): boolean {
    // contains only lowercase letters, digits and hyphens
    const pattern = /^[a-z0-9\-]+$/;

    return pattern.test(automationProcessName);
  }

  private static removeSpecialCharacters(value: string): string {
    return value.replace(/[^a-z0-9\-]/g, ' ');
  }
}
