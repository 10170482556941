import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigService } from '../config/config.service';
import { Observable, map } from 'rxjs';
import {
  CreateDeploymentScopeResponseModel,
  DeploymentScopeResponseModel,
  IResourcesScopingModel,
} from './deployment-scope.model';
import { ScopingType } from '../../models/enums/scoping-type.enum';
import { ApplicationTier } from '../../models/enums/application-tier.enum';

@Injectable({
  providedIn: 'root',
})
export class DeploymentScopesService {
  private httpClient = inject(HttpClient);
  private config = inject(AppConfigService);
  private baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public createDeploymentScope(
    automationProcessId: number,
    resourceTypes: string[],
    scopingType: ScopingType,
    tags?: Record<string, string>,
    resourcesScoping?: IResourcesScopingModel,
    managementGroupIds?: number[],
    businessApplicationIds?: number[],
    digitalPlatformId?: number,
    applicationTiers?: ApplicationTier[],
  ): Observable<CreateDeploymentScopeResponseModel> {
    const requestBody = {
      automationProcessId: automationProcessId,
      resourceTypes: resourceTypes,
      tags: tags,
      resourcesScoping: resourcesScoping,
      managementGroupIds: managementGroupIds,
      businessApplicationIds: businessApplicationIds,
      scopingType: scopingType,
      digitalPlatformId: digitalPlatformId,
      applicationTiers: applicationTiers,
    };

    return this.httpClient.post<CreateDeploymentScopeResponseModel>(`${this.baseUrl}/deployment-scopes`, requestBody);
  }

  public getDeploymentScope(deploymentScopeId: number): Observable<DeploymentScopeResponseModel> {
    return this.httpClient.get<DeploymentScopeResponseModel>(`${this.baseUrl}/deployment-scopes/${deploymentScopeId}`);
  }
}
