<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scope for Business Applications</mat-card-title>
    @if (businessApplicationsLoading) {
      <mat-spinner [diameter]="75" class="m-3"></mat-spinner>
    } @else {
      @if (!readOnly) {
        <mat-form-field>
          <mat-label>Business Applications</mat-label>
          <input matInput type="text" [matAutocomplete]="businessApplicationsAutocomplete" [formControl]="formGroup.controls.searchText" />
          <mat-autocomplete #businessApplicationsAutocomplete="matAutocomplete" (optionSelected)="onBusinessApplicationSelected($event)">
            @for (application of filteredApplications | async; track application) {
              <mat-option [value]="application"
                >{{ application.serviceId }} - {{ application.name }} - {{ application.shortName }} - {{ application.technicalOwner }}</mat-option
              >
            }
          </mat-autocomplete>
        </mat-form-field>
      }

      <table mat-table #selectedApplicationsTable [dataSource]="formGroup.value.selectedApplications">
        <ng-container matColumnDef="serviceId">
          <th mat-header-cell *matHeaderCellDef>ServiceId</th>
          <td mat-cell *matCellDef="let application">{{ application.serviceId }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let application">{{ application.name }}</td>
        </ng-container>

        <ng-container matColumnDef="shortName">
          <th mat-header-cell *matHeaderCellDef>Short Name</th>
          <td mat-cell *matCellDef="let application">{{ application.shortName }}</td>
        </ng-container>

        <ng-container matColumnDef="technicalOwner">
          <th mat-header-cell *matHeaderCellDef>Technical Owner</th>
          <td mat-cell *matCellDef="let application">{{ application.technicalOwner }}</td>
        </ng-container>

        <ng-container matColumnDef="removeIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let application">
            @if (!readOnly) {
              <button mat-icon-button (click)="onBusinessApplicationRemoved(application)">
                <mat-icon>delete</mat-icon>
              </button>
            }
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">No Applications Selected</td>
        </tr>
      </table>
    }
  </mat-card-content>
</mat-card>
