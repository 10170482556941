import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExceptionScopingType } from '../../../models/enums/scoping-type.enum';

@Component({
  selector: 'exception-scoping-type-selector',
  templateUrl: './exception-scoping-type-selector.component.html',
})
export class ExceptionScopingTypeSelectorComponent implements OnInit {
  exceptionScopingType = ExceptionScopingType;

  @Input({ required: true }) formGroup: FormGroup<ExceptionScopingTypeSelectorForm>;
  ngOnInit(): void {}

  public static buildFormGroup(): FormGroup<ExceptionScopingTypeSelectorForm> {
    return new FormGroup<ExceptionScopingTypeSelectorForm>({
      exceptionScopingTypes: new FormControl<ExceptionScopingType[]>([], Validators.required),
    });
  }
}

export interface ExceptionScopingTypeSelectorForm {
  exceptionScopingTypes: FormControl<ExceptionScopingType[]>;
}
