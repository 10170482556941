import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, concatMap } from 'rxjs';
import { AppConfigService } from '../config/config.service';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({
  providedIn: 'root',
})
export class CalAuthorizationInterceptor implements HttpInterceptor {
  config = inject(AppConfigService);
  authService = inject(CalAngularService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAADToken(this.config.oidcScopes).pipe(
      concatMap((token) => {
        const httpHeaders = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const request = req.clone({ headers: httpHeaders });
        return next.handle(request);
      }),
    );
  }
}
