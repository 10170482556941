import { Component, OnInit, inject } from '@angular/core';
import { PageLayout } from '@cvx/nextpage';
import { ApprovalType, ApprovalTypeMapper } from '../../models/enums/approval-type.enum';
import { AutomationProcessType, AutomationProcessTypeMapper } from '../../models/enums/automation-process-type.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AutomationProcessService } from '../../services/automation-process/automation-process.service';
import { IAzureResourceType } from '../../models/azure-models/azure-resource-type.model';
import { Observable, catchError, finalize, map, startWith, tap, throwError } from 'rxjs';
import { IBusinessCapability } from '../../services/service-now-resources.service/service-now-resources.model';
import { ServiceNowResourcesService } from '../../services/service-now-resources.service/service-now-resources.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'create-automation-process-view',
  templateUrl: './create-automation-process.view.html',
  styleUrls: ['./create-automation-process.view.scss'],
})
export class CreateAutomationProcessView implements OnInit {
  automationProcessService = inject(AutomationProcessService);
  serviceNowService = inject(ServiceNowResourcesService);
  toastService = inject(ToastService);

  PageLayout = PageLayout;
  isFormSubmitting: boolean = false;

  automationProcessForm = new FormGroup({
    name: new FormControl('', { validators: [Validators.required, Validators.maxLength(128)] }),
    description: new FormControl('', { validators: [Validators.required, Validators.maxLength(2000)] }),
    automationProcessType: new FormControl<AutomationProcessType>(null, Validators.required),
    approvalTypes: new FormControl<ApprovalType[]>([], Validators.required),
    resourceTypes: new FormControl(''),
    businessCapability: new FormControl<IBusinessCapability>(null, Validators.required),
    digitalPlatform: new FormControl(''),
    supportGroup: new FormControl(''),
    requiresMaintenanceSchedule: new FormControl(false),
  });

  approvalTypes: { value: ApprovalType; displayName: string }[] = [];
  automationProcesstypes = AutomationProcessType;

  filteredResourceTypes: Observable<IAzureResourceType[]>;
  selectedResourceTypes: IAzureResourceType[] = [];
  resourceTypes = this.automationProcessService.getResourceTypes();

  businessCapabilitiesSelectorIsLoading: boolean = true;
  businessCapabilities: IBusinessCapability[] = [];
  filteredCapabilities: Observable<IBusinessCapability[]>;

  ngOnInit(): void {
    this.approvalTypes = Object.values(ApprovalType).map((value: ApprovalType) => {
      return {
        value: value,
        displayName: ApprovalTypeMapper.toDisplayString(value),
      };
    });

    this.filteredResourceTypes = this.automationProcessForm.controls.resourceTypes.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResourceTypes(value)),
    );

    this.serviceNowService
      .getBusinessCapabilities()
      .pipe(tap(() => (this.businessCapabilitiesSelectorIsLoading = false)))
      .subscribe((sub) => {
        this.businessCapabilities = sub;

        this.filteredCapabilities = this.automationProcessForm.controls.businessCapability.valueChanges.pipe(
          startWith(''),
          map((value: string) => this._filterBusinessCapabilities(value || '')),
        );
      });

    this.automationProcessForm.controls.businessCapability.valueChanges.subscribe((value: IBusinessCapability) =>
      this.onBusinessCapabilitySelected(value),
    );
  }

  displayAutomationProcessType(value: AutomationProcessType): string {
    return AutomationProcessTypeMapper.toDisplayString(value);
  }

  onBusinessCapabilitySelected(value: IBusinessCapability): void {
    this.automationProcessForm.patchValue({
      digitalPlatform: value?.digitalPlatformName,
      supportGroup: value?.supportGroupName,
    });
  }

  onSubmitAutomationProcess(): void {
    const value = this.automationProcessForm.value;

    const name = value.name;
    const description = value.description;
    const automationProcessType = value.automationProcessType;
    const approvalTypes = value.approvalTypes;
    const resourceTypes = this.selectedResourceTypes.map((value: IAzureResourceType) => value.value);
    const businessCapabilityId = value.businessCapability.id;
    const requiresMaintenanceSchedule = value.requiresMaintenanceSchedule;

    this.automationProcessForm.disable();
    this.isFormSubmitting = true;

    this.automationProcessService
      .createAutomationProcess(
        name,
        description,
        automationProcessType,
        approvalTypes,
        resourceTypes,
        businessCapabilityId,
        requiresMaintenanceSchedule,
      )
      .pipe(
        catchError((err) => {
          this.toastService.showError('Failed to create automation process.');

          return throwError(() => err);
        }),
        finalize(() => {
          this.automationProcessForm.enable();
          this.isFormSubmitting = false;
        }),
      )
      .subscribe((sub) => {
        this.toastService.showSuccess('Automation process created!');
      });
  }

  private _filterResourceTypes(value: string): IAzureResourceType[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const remainingElements = this.resourceTypes.filter((filterValue) => this.selectedResourceTypes.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingElements.filter((filterValue) => filterValue.displayName.toLowerCase().search(searchValue) > -1);
  }

  private _filterBusinessCapabilities(value: string): IBusinessCapability[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const searchValue = value.toLowerCase();

    return this.businessCapabilities.filter((filterValue) => filterValue.name.toLowerCase().search(searchValue) > -1);
  }

  removeResourceType(resourceType: IAzureResourceType): void {
    const index = this.selectedResourceTypes.indexOf(resourceType);
    if (index >= 0) {
      this.selectedResourceTypes.splice(index, 1);
    }

    this.filteredResourceTypes = this.automationProcessForm.controls.resourceTypes.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResourceTypes(value)),
    );
  }

  onResourceTypeSelected(event: any): void {
    const selectedResourceType = event.option.value as IAzureResourceType;
    if (this.selectedResourceTypes.includes(selectedResourceType)) {
      return;
    }

    this.selectedResourceTypes.push(selectedResourceType);
    this.automationProcessForm.controls.resourceTypes.setValue('');
  }

  displayBusinessCapabilityName(option?: IBusinessCapability): string {
    return option?.name ?? '';
  }
}
