import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit, inject } from '@angular/core';
import { AzureService } from '../../../../services/azure-service.service/azure-service.service';
import { ManagementGroupTreeNode } from '../../../../services/azure-service.service/azure-service.model';
import { Observable, filter, map, startWith, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface FlatTreeNode {
  id: string;
  name: string;
  displayName: string;
  type: string;
  totalSubscriptions: number;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'management-groups',
  templateUrl: './management-groups.component.html',
  styleUrls: ['./management-groups.component.scss'],
})
export class ManagementGroupsComponent implements OnInit {
  private azureService = inject(AzureService);

  @Input({ required: true }) readonly: boolean = false;
  @Input({ required: true }) formGroup: FormGroup<ManagementGroupForm>;

  managementGroups: ManagementGroupModel[] = [];
  filteredManagementGroups: Observable<ManagementGroupModel[]>;

  managementGroupsSelectorIsLoading: boolean = true;

  displayedColumns: string[] = ['displayName', 'type', 'name', 'totalSubscriptions'];

  treeControl = new FlatTreeControl<FlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.treeTransformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  private treeTransformer(node: ManagementGroupTreeNode, level: number): FlatTreeNode {
    return {
      expandable: !!node?.children && node?.children?.length > 0,
      level: level,
      id: node?.id,
      name: node?.name,
      displayName: node?.displayName,
      type: node?.type,
      totalSubscriptions: node?.totalSubscriptions,
    } as FlatTreeNode;
  }

  private _filterManagementGroups(value: string): ManagementGroupModel[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }

    let remainingElements = this.managementGroups.filter((filterValue) => this.formGroup.value.managementGroups.indexOf(filterValue) === -1);

    const treeIdsToRemove = this.dataSource.data.map((element) => this.flattenTree(element)).flat();
    remainingElements = this.managementGroups.filter((filterValue) => treeIdsToRemove.indexOf(filterValue.resourceId) === -1);

    const searchValue = value.toLowerCase();

    return remainingElements.filter((filterValue) => filterValue.displayName.toLowerCase().search(searchValue) > -1);
  }

  flattenTree(rootNode: ManagementGroupTreeNode): string[] {
    let resourceIds: string[] = [];

    if (rootNode.children.length > 0) {
      resourceIds = rootNode.children.map((child) => this.flattenTree(child)).flat();
    }

    return [rootNode.id, ...resourceIds];
  }

  mapManagementGroupTypeDisplayName(value: string): string {
    switch (value) {
      case 'Microsoft.Management/managementGroups/subscriptions':
        return 'Subscription';
      case 'Microsoft.Management/managementGroups':
        return 'Management group';
      default:
        return value;
    }
  }

  mapManagementGroupTypeIcon(value: string): string {
    switch (value) {
      case 'Microsoft.Management/managementGroups/subscriptions':
        return 'azure-subscription';
      case 'Microsoft.Management/managementGroups':
        return 'azure-management-group';
      default:
        return value;
    }
  }

  displayTotalSubscriptions(value: FlatTreeNode): string {
    if (value.type === 'Microsoft.Management/managementGroups') {
      return `${value.totalSubscriptions}`;
    }

    return null;
  }

  populateManagementGroupsTree(managementGroups: ManagementGroupModel[]): void {
    managementGroups.forEach((value) => {
      this.azureService.getManagementGroupDescendantsTree(value.name).subscribe((sub) => {
        let data = this.dataSource.data;
        data.push(sub);
        this.dataSource.data = data;

        this.formGroup.controls.selector.setValue('');
      });
    });
  }

  onManagementGroupSelected(event: any): void {
    const selectedManagementGroup = event.option.value as ManagementGroupModel;
    if (this.formGroup.value.managementGroups.includes(selectedManagementGroup)) {
      return;
    }

    let managementGroups = this.formGroup.value.managementGroups;
    managementGroups.push(selectedManagementGroup);

    this.formGroup.controls.managementGroups.setValue(managementGroups);

    this.azureService.getManagementGroupDescendantsTree(selectedManagementGroup.name).subscribe((sub) => {
      let data = this.dataSource.data;
      data.push(sub);
      this.dataSource.data = data;

      this.formGroup.controls.selector.setValue('');
    });
  }

  onRemoveManagementGroup(value: ManagementGroupModel): void {
    const index = this.formGroup.value.managementGroups.indexOf(value);
    if (index === -1) {
      return;
    }

    this.formGroup.value.managementGroups.splice(index, 1);
    this.removeManagementGroupFromTree(value);
    this.formGroup.controls.selector.setValue('');
  }

  removeManagementGroupFromTree(value: ManagementGroupModel): void {
    let treeData = this.dataSource.data;
    const treeIndex = treeData.findIndex((x) => x.id === value.resourceId);
    treeData.splice(treeIndex, 1);
    this.dataSource.data = treeData;
  }

  displayManagementGroupName(option?: ManagementGroupModel): string {
    return option?.displayName ?? '';
  }

  ngOnInit(): void {
    this.azureService
      .getManagementGroups()
      .pipe(tap(() => (this.managementGroupsSelectorIsLoading = false)))
      .subscribe((sub) => {
        this.managementGroups = sub.map((x) => {
          return {
            id: x.id,
            resourceId: x.resourceId,
            name: x.name,
            displayName: x.displayName,
          } as ManagementGroupModel;
        });

        this.filteredManagementGroups = this.formGroup.controls.selector.valueChanges.pipe(
          startWith(''),
          map((value: string) => this._filterManagementGroups(value)),
        );
      });

    this.populateManagementGroupsTree(this.formGroup.value.managementGroups);
  }

  public static buildManagementGroupForm(): FormGroup<ManagementGroupForm> {
    return new FormGroup<ManagementGroupForm>({
      managementGroups: new FormControl<ManagementGroupModel[]>([], Validators.required),
      selector: new FormControl(),
    });
  }
}

export interface ManagementGroupModel {
  id: number;
  resourceId: string;
  name: string;
  displayName: string;
}

export interface ManagementGroupForm {
  managementGroups: FormControl<ManagementGroupModel[]>;
  selector: FormControl;
}
