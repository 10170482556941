<mat-card>
  <mat-card-content>
    <mat-card-title>Select Exception</mat-card-title>
    <div [formGroup]="formGroup">
      <mat-radio-group formControlName="exception" style="display: flex; flex-direction: column">
        @for (exception of exceptions; track exception) {
          <mat-radio-button (change)="onSelectionChanged(exception)" [value]="exception">
            {{ getExceptionFriendlyName(exception) }}
          </mat-radio-button>
        }
      </mat-radio-group>
    </div>
  </mat-card-content>
</mat-card>
