<div class="confirmation-dialog">
  <h2 mat-dialog-title>{{ data.headerText }}</h2>
  <mat-dialog-content class="mat-typography">
    <div class="mb-4">
      <p>Enter your email '{{ data.userEmail }}' to verify operation.</p>
      <mat-form-field>
        <mat-label>{{ data.userEmail }}</mat-label>
        <input matInput [placeholder]="data.userEmail" [formControl]="formGroup.controls.emailValidation" />
        @if (formGroup.controls.emailValidation.hasError('emailDoesNotMatch')) {
        <mat-error>Email does not match.</mat-error>
        }
      </mat-form-field>
    </div>

    <div>
      <p>Add Comment</p>
      <mat-form-field>
        <mat-label>Comment</mat-label>
        <input matInput [formControl]="formGroup.controls.comment" />
        @if (formGroup.controls.comment.hasError('required')) {
        <mat-error>Comment is required.</mat-error>
        }
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mb-2 mr-3">
    <button mat-raised-button (click)="onClose()">Cancel</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="onConfirm()" [disabled]="formGroup.invalid">Ok</button>
  </mat-dialog-actions>
</div>
