import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IDigitalPlatform, IGetDigitalPlatformsResponse } from './service-now-resources.model';
import { AppConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceNowResourcesService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getDigitalPlatforms(): Observable<IDigitalPlatform[]> {
    return this.httpClient
      .get<IGetDigitalPlatformsResponse>(`${this.baseUrl}/service-now/digital-platforms`)
      .pipe(map((data: IGetDigitalPlatformsResponse) => data.options));
  }
}
