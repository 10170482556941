import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { map, tap } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import {
  CloudCentralRequestReviewerModel,
  CloudCentralRequestReviewerOrigin,
  CloudCentralRequestReviewerStatus,
  CloudCentralRequestReviewerTreeNode,
} from '../../../services/requests/requests.model';
import { CloudCentralRequestsService } from '../../../services/requests/requests.service';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';

interface FlatTreeNode {
  id: string;
  name: string;
  comment: string;
  status: string;
  displayName: string;
  type: string;
  totalSubscriptions: number;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'reviewers-list',
  templateUrl: './reviewers-list.component.html',
  styleUrl: './reviewers-list.component.scss',
})
export class ReviewersListComponent implements OnInit, OnChanges {
  private cloudCentralRequestsService = inject(CloudCentralRequestsService);
  private authService = inject(CalAngularService);

  @Input({ required: true }) cloudCentralRequestId: number;
  @Input({ required: true }) title: string;

  currentUserProfile: ICvxClaimsPrincipal;
  reviewersLoading: boolean = true;
  buttonsState: 'hidden' | 'disabled' | 'enabled' = 'hidden';

  treeControl = new FlatTreeControl<FlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.treeTransformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  treeTransformer(node: CloudCentralRequestReviewerTreeNode, level: number): FlatTreeNode {
    let flatNode = {
      expandable: !!node?.children && node?.children?.length > 0,
      level: level,
      comment: node.comment,
      status: node.status,
      displayName: node.displayName,
    } as FlatTreeNode;

    return flatNode;
  }

  ngOnInit(): void {
    if (this.cloudCentralRequestId) {
      this.reviewersLoading = true;
      this.subscribeToAuthService();
      this.populateTree(this.cloudCentralRequestId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cloudCentralRequestId'] && this.cloudCentralRequestId) {
      this.reviewersLoading = true;
      this.subscribeToAuthService();
      this.populateTree(this.cloudCentralRequestId);
    }
  }

  populateTree(id: number) {
    this.authService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        const currentUserEmail = this.currentUserProfile.userName.toLocaleLowerCase();

        this.cloudCentralRequestsService
          .getReviewers(id)
          .pipe(
            tap(() => (this.reviewersLoading = false)),
            map((reviewers: CloudCentralRequestReviewerModel[]) => {
              const groupedReviewers = reviewers.reduce(
                (grouped, reviewer) => {
                  if (!grouped[reviewer.origin]) {
                    grouped[reviewer.origin] = [];
                  }
                  grouped[reviewer.origin].push(reviewer);
                  return grouped;
                },
                {} as { [key: string]: CloudCentralRequestReviewerModel[] },
              );

              return Object.keys(groupedReviewers).map((origin) => ({
                origin: origin as CloudCentralRequestReviewerOrigin,
                children: groupedReviewers[origin] as CloudCentralRequestReviewerTreeNode[],
              }));
            }),
            map((treeNodes: CloudCentralRequestReviewerTreeNode[]) => {
              treeNodes.forEach((node) => {
                node.displayName = this.mapOriginDisplayName(node.origin);
                let hasRejected = false;
                let hasApproved = false;
                node.status = CloudCentralRequestReviewerStatus.Pending;

                node.children.forEach((child) => {
                  child.displayName = `${child.name} (${child.email})`;

                  if (child.status === CloudCentralRequestReviewerStatus.Rejected) {
                    hasRejected = true;
                  }
                  if (child.status === CloudCentralRequestReviewerStatus.Approved) {
                    hasApproved = true;
                  }

                  if ((hasApproved || hasRejected) && child.email.toLocaleLowerCase() === currentUserEmail) {
                    this.buttonsState = 'disabled';
                  }

                  if (child.status === CloudCentralRequestReviewerStatus.Pending && child.email.toLocaleLowerCase() === currentUserEmail) {
                    this.buttonsState = 'enabled';
                  }
                });

                if (hasRejected) {
                  node.status = CloudCentralRequestReviewerStatus.Rejected;
                } else if (hasApproved) {
                  node.status = CloudCentralRequestReviewerStatus.Approved;
                }
              });

              return treeNodes;
            }),
          )
          .subscribe((groupedReviewers: CloudCentralRequestReviewerTreeNode[]) => {
            this.dataSource.data = groupedReviewers;
          });
      }
    });
  }

  getStatusIconName(status: CloudCentralRequestReviewerStatus): string {
    switch (status) {
      case CloudCentralRequestReviewerStatus.Approved:
        return 'check';
      case CloudCentralRequestReviewerStatus.Rejected:
        return 'close';
      case CloudCentralRequestReviewerStatus.Pending:
        return 'schedule';
    }
    return '';
  }

  mapOriginDisplayName(value: CloudCentralRequestReviewerOrigin): string {
    switch (value) {
      case CloudCentralRequestReviewerOrigin.Application:
        return 'Application';
      case CloudCentralRequestReviewerOrigin.ManagementGroup:
        return 'Management Group';
      case CloudCentralRequestReviewerOrigin.Platform:
        return 'Platform';
      case CloudCentralRequestReviewerOrigin.RiskManager:
        return 'Risk Manager';
      case CloudCentralRequestReviewerOrigin.SupportGroup:
        return 'Support Group';
      default:
        return '';
    }
  }

  onRejectRequestClick(): void {
    this.cloudCentralRequestsService.rejectRequest(this.currentUserProfile.userName, this.cloudCentralRequestId);
  }
  onApproveRequestClick(): void {
    this.cloudCentralRequestsService.approveRequest(this.currentUserProfile.userName, this.cloudCentralRequestId);
  }

  private subscribeToAuthService(): void {
    this.authService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      }
    });
  }
}
