<mat-card>
  <mat-card-content>
    <mat-card-title>Select Business Application</mat-card-title>
    @if (optionsLoading) {
      <mat-spinner [diameter]="75" class="m-3"></mat-spinner>
    } @else if (!readonly) {
      <mat-form-field>
        <mat-label>Search Application</mat-label>
        <input type="text" matInput [matAutocomplete]="businessApplicationAutocomplete" [formControl]="formGroup.controls.searchText" />
        <mat-autocomplete
          #businessApplicationAutocomplete="matAutocomplete"
          (optionSelected)="onBusinessApplicationSelected($event)"
          [displayWith]="displayEmpty"
        >
          @for (application of filteredApplications | async; track application) {
            <mat-option [value]="application"
              >{{ application.serviceId }} - {{ application.name }} - {{ application.shortName }} - {{ application.technicalOwner }}</mat-option
            >
          }
        </mat-autocomplete>
      </mat-form-field>
    }
    @if (applicationDetailsLoading) {
      <mat-spinner [diameter]="75" class="m-3"></mat-spinner>
    } @else if (!applicationDetailsLoading && applicationSelected) {
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Service Id</mat-label>
            <input type="text" matInput [formControl]="formGroup.controls.application.controls.serviceId" [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Application Name</mat-label>
            <input type="text" matInput [formControl]="formGroup.controls.application.controls.name" [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Technical Owner</mat-label>
            <input type="text" matInput [formControl]="formGroup.controls.application.controls.technicalOwner" [readonly]="true" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Business Capability</mat-label>
            <input type="text" matInput [formControl]="formGroup.controls.application.controls.businessCapability" [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Digital Platform</mat-label>
            <input type="text" matInput [formControl]="formGroup.controls.application.controls.digitalPlatform" [readonly]="true" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Support Group</mat-label>
            <input type="text" matInput [formControl]="formGroup.controls.application.controls.supportGroup" [readonly]="true" />
          </mat-form-field>
        </div>
      </div>
    }
  </mat-card-content>
</mat-card>
