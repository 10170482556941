import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigService } from '../config/config.service';
import { Observable, map } from 'rxjs';
import { ITimeZoneModel, ITimeZonesResponse } from './options.model';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  private httpClient = inject(HttpClient);
  private config = inject(AppConfigService);
  private baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getTimeZones(): Observable<ITimeZoneModel[]> {
    return this.httpClient.get(`${this.baseUrl}/options/time-zones`).pipe(map((data: ITimeZonesResponse) => data.result));
  }
}
