import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TagFilterForm } from './tag-filter.component.model';
import { DeploymentScopeDataService } from '../service/deployment-scope-data.service';

@Component({
  selector: 'tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss'],
})
export class TagFilterComponent implements OnInit {
  dataService = inject(DeploymentScopeDataService);

  @Input({ required: true }) tagFilters: FormGroup<TagFilterForm>;
  @Input() readonly: boolean = false;
  @Output() tagsChanged = new EventEmitter<Record<string, string>>();

  ngOnInit(): void {}

  onClearTagValuesClicked(): void {
    this.clearInputFields();
  }

  onAddTagFilterClicked(): void {
    const tagName = this.tagFilters.controls.tagName.value;
    const tagValue = this.tagFilters.controls.tagValue.value;

    if (!tagValue) {
      this.tagFilters.controls.tagValue.setErrors({ required: true });
    }

    if (!tagName) {
      this.tagFilters.controls.tagName.setErrors({ required: true });
    }

    if (tagName in this.tagFilters.value.tags) {
      this.tagFilters.controls.tagName.setErrors({ tagExists: true });
    }

    if (this.tagFilters.controls.tagName.invalid || this.tagFilters.controls.tagValue.invalid) {
      return;
    }

    let tagValues = this.tagFilters.value.tags;
    tagValues[tagName] = tagValue;

    this.tagFilters.controls.tags.setValue(tagValues);

    this.clearInputFields();

    this.emiTagsChanged();
  }

  clearInputFields(): void {
    this.tagFilters.controls.tagName.reset();
    this.tagFilters.controls.tagValue.reset();
  }

  onRemoveTagFilter(tagKey: string): void {
    delete this.tagFilters.value.tags[tagKey];

    this.emiTagsChanged();
  }

  emiTagsChanged(): void {
    const tags = this.tagFilters.value.tags;

    this.tagsChanged.emit(tags);
    this.dataService.changeTags(tags);
  }

  getTagFilterErrorMessage(formControl: FormControl): string {
    if (formControl.hasError('required')) {
      return 'You must enter a value';
    }

    if (formControl.hasError('tagExists')) {
      return `Tag with name '${formControl.value}' already exists`;
    }

    return '';
  }

  public static buildTagFilterForm(): FormGroup<TagFilterForm> {
    return new FormGroup<TagFilterForm>({
      tagName: new FormControl(''),
      tagValue: new FormControl(''),
      tags: new FormControl<Record<string, string>>({}),
    });
  }
}
