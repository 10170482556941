import { Injectable, inject } from '@angular/core';
import { IAzureResourceType } from '../../models/azure-models/azure-resource-type.model';
import { IAutomationProcessRequest } from '../../models/automation-process-models/automation-process-request.model';
import { ApplicationTier } from '../../models/enums/application-tier.enum';
import { IAutomationProcessReviewEntry } from '../../models/automation-process-models/automation-process-review-entry.model';
import { HttpClient } from '@angular/common/http';
import { IDigitalPlatform } from '../service-now-resources.service/service-now-resources.model';
import { IAzureResourceGroup, IAzureSubscription } from '../azure-service.service/azure-service.model';
import { AutomationProcessType } from '../../models/enums/automation-process-type.enum';
import { ApprovalType } from '../../models/enums/approval-type.enum';
import { AppConfigService } from '../config/config.service';
import { Observable, map } from 'rxjs';
import { IAutomationProcessCollectionResponse, IAutomationProcessResponse } from './automation-process.model';

@Injectable({
  providedIn: 'root',
})
export class AutomationProcessService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getResourceTypes(): IAzureResourceType[] {
    const resourceTypes: IAzureResourceType[] = [
      {
        value: 'Microsoft.Compute/virtualMachines',
        displayName: 'Virtual Machine',
        image: 'azure-virtual-machine',
      },
      {
        value: 'Microsoft.Storage/storageAccounts',
        displayName: 'Storage Account',
        image: 'azure-storage-account',
      },
      {
        value: 'Microsoft.HybridCompute/machines',
        displayName: 'Azure Arc Machine',
        image: 'azure-arc-machine',
      },
      {
        value: 'Microsoft.Web/sites',
        displayName: 'App Service',
        image: 'azure-app-service',
      },
      {
        value: 'Microsoft.Networking/virtualNetworks',
        displayName: 'Virtual Network',
        image: 'azure-virtual-network',
      },
    ];

    return resourceTypes;
  }

  public getAutomationProcessRequest(_: string): IAutomationProcessRequest {
    const allResourceTypes = this.getResourceTypes();
    const digitalPlatforms: IDigitalPlatform[] = [];
    const subscriptions: IAzureSubscription[] = [];
    const resourceGroups: IAzureResourceGroup[] = [];

    return {
      name: 'Auto-Generated automation process name #1',
      businessCapability: undefined,
      resourceTypes: [allResourceTypes[0], allResourceTypes[2]],
      deploymentScope: {
        digitalPlatforms: [digitalPlatforms[1]],
        subscriptions: [subscriptions[0], subscriptions[2]],
        resourceGroups: [resourceGroups[1]],
        applicationTiers: [ApplicationTier.Tier1, ApplicationTier.Tier2],
      },
    };
  }

  public createAutomationProcess(
    name: string,
    description: string,
    type: AutomationProcessType,
    approvalTypes: ApprovalType[],
    resourceTypes: string[],
    businessCapabilityId: number,
    requiresMaintenanceSchedule: boolean,
  ): Observable<Object> {
    const requestBody = {
      name: name,
      description: description,
      type: type,
      approvalTypes: approvalTypes,
      resourceTypes: resourceTypes,
      businessCapabilityId: businessCapabilityId,
      requiresMaintenanceSchedule: requiresMaintenanceSchedule,
    };

    return this.httpClient.post(`${this.baseUrl}/automation-processes`, requestBody);
  }

  public getAutomationProcesses(): Observable<IAutomationProcessResponse[]> {
    return this.httpClient
      .get<IAutomationProcessCollectionResponse>(`${this.baseUrl}/automation-processes`)
      .pipe(map((data: IAutomationProcessCollectionResponse) => data.result));
  }
}
