<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scope for Resources</mat-card-title>
    @if (subscriptionsSelectorIsLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    } @else {
      <div>
        <mat-form-field>
          <mat-label>Subscriptions</mat-label>
          <input matInput type="text" [matAutocomplete]="subscriptionsAutocomplete" [formControl]="subscriptionSelector" [disabled]="readonly" />
          <mat-autocomplete #subscriptionsAutocomplete="matAutocomplete" (optionSelected)="onSubscriptionSelected($event)">
            @for (subscription of filteredSubscriptions | async; track subscription.id) {
              <mat-option [value]="subscription">
                <mat-icon svgIcon="azure-subscription"></mat-icon>
                {{ subscription.displayName + " - " + subscription.id }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-listbox class="pt-1">
          @for (subscription of formGroup.value.subscriptions; track subscription.id) {
            <mat-chip (removed)="removeSubscription(subscription)" [disabled]="readonly">
              <mat-icon svgIcon="azure-subscription"></mat-icon>
              {{ subscription.displayName }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          }
        </mat-chip-listbox>
      </div>
    }
    @if (showResouceGroupsSelection()) {
      <mat-divider class="m-1" />
      <div>
        <mat-form-field>
          <mat-label>Resource Groups</mat-label>
          <input matInput type="text" [matAutocomplete]="resourceGroupsAutocomplete" [formControl]="resourceGroupsSelector" [disabled]="readonly" />
          <mat-autocomplete #resourceGroupsAutocomplete="matAutocomplete" class="azure-tag-chip" (optionSelected)="onResourceGroupSelected($event)">
            @for (resourceGroup of filteredResourceGroups | async; track resourceGroup.name) {
              <mat-option [value]="resourceGroup">
                <mat-icon svgIcon="azure-resource-group"></mat-icon>
                {{ resourceGroup.name }}
                @for (tag of resourceGroup.tags | keyvalue; track tag.key) {
                  <mat-chip>{{ tag.key + ": " + tag.value }}</mat-chip>
                }
              </mat-option>
            }
            @if (!(filteredResourceGroups | async)?.length) {
              <mat-option disabled>No resource groups available</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-listbox class="pt-1">
          @for (resourceGroup of formGroup.value.resourceGroups; track resourceGroup.name) {
            <mat-chip (removed)="removeResourceGroup(resourceGroup)" [disabled]="readonly">
              <mat-icon svgIcon="azure-resource-group"></mat-icon>
              {{ resourceGroup.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          }
        </mat-chip-listbox>
      </div>
    } @else if (resourceGroupsSelectorIsLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    }
    @if (showResourcesSelection()) {
      <mat-divider class="m-1" />
      <div>
        <mat-form-field>
          <mat-label>Azure Resources</mat-label>
          <input matInput type="text" [matAutocomplete]="resourcesAutocomplete" [formControl]="resourcesSelector" [disabled]="readonly" />
          <mat-autocomplete #resourcesAutocomplete="matAutocomplete" (optionSelected)="onResourceSelected($event)" class="azure-tag-chip">
            @for (resource of filteredResources | async; track resource.id) {
              <mat-option [value]="resource">
                <mat-icon svgIcon="azure-resource-group"></mat-icon>
                {{ resource.name + " " + resource.type }}
                @for (tag of resource.tags | keyvalue; track tag.key) {
                  <mat-chip>{{ tag.key + ": " + tag.value }}</mat-chip>
                }
              </mat-option>
            }
            @if (!(filteredResources | async)?.length) {
              <mat-option disabled>No resources available</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-listbox class="pt-1">
          @for (resource of formGroup.value.resources; track resource.id) {
            <mat-chip (removed)="removeResource(resource)" [disabled]="readonly">
              <mat-icon svgIcon="azure-resource-group"></mat-icon>
              {{ resource.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          }
        </mat-chip-listbox>
      </div>
    } @else if (resourcesSelectorIsLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    }
  </mat-card-content>
</mat-card>
