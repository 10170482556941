export enum DeploymentScopeType {
  DigitalPlatform = 'DigitalPlatform',
  Resources = 'Resources',
  ManagementGroup = 'ManagementGroup',
}

export enum DeploymentScopeInclusionType {
  Included = 'Included',
  Excluded = 'Excluded',
}
