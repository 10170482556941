import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private snackBar = inject(MatSnackBar);
  private defaultDuration = 10_000;
  private defaultHorizontalPosition: MatSnackBarHorizontalPosition = 'end';
  private defaultVerticalPosition: MatSnackBarVerticalPosition = 'bottom';

  showSuccess(message: string): void {
    const config = this.getDefaultConfig('success-snackbar');

    this.snackBar.open(message, 'X', config);
  }

  showError(message: string): void {
    const config = this.getDefaultConfig('error-snackbar');

    this.snackBar.open(message, 'X', config);
  }

  private getDefaultConfig(panelClass: string): MatSnackBarConfig {
    let config = new MatSnackBarConfig();
    config.duration = this.defaultDuration;
    config.panelClass = [panelClass];
    config.horizontalPosition = this.defaultHorizontalPosition;
    config.verticalPosition = this.defaultVerticalPosition;

    return config;
  }
}
