import { Component, EventEmitter, Input, OnInit, Output, inject, input } from '@angular/core';
import { IAzureResourceType } from '../../../models/azure-models/azure-resource-type.model';
import { Observable, map, startWith, tap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { DeploymentScopeDataService } from '../service/deployment-scope-data.service';
import { OperatingSystemType } from '../../../models/enums/operating-system-type.enum';
import { IAutomationProcessResponse } from '../../../services/automation-process/automation-process.model';
import { AutomationProcessService } from '../../../services/automation-process/automation-process.service';

@Component({
  selector: 'resources-selector',
  templateUrl: './resources-selector.component.html',
})
export class ResourcesSelectorComponent implements OnInit {
  automationProcessService = inject(AutomationProcessService);
  dataService = inject(DeploymentScopeDataService);


  private _resourceTypes: IAzureResourceType[] = [];
  private _operatingSystemType: OperatingSystemType;
  public operatingSystemTypesAvailable: OperatingSystemType[] = Object.values(OperatingSystemType);

  @Output() resourceTypesChanged = new EventEmitter<IAzureResourceType[]>();
  selector = new FormControl();

  @Input({ required: true }) formGroup: FormGroup<ResourcesSelectorForm>;
  @Input() readOnly: boolean = false;

  resourceTypes: IAzureResourceType[] = [];
  filteredResourceTypes: Observable<IAzureResourceType[]>;
  availableOperatingSystemType: Observable<OperatingSystemType>;

  onResourceTypeSelected(event: any): void {
    const selectedResourceType = event.option.value as IAzureResourceType;
    if (this.formGroup.value.selectedTypes.includes(selectedResourceType)) {
      return;
    }

    let selectedResourceTypes = this.formGroup.value.selectedTypes;
    selectedResourceTypes.push(selectedResourceType);

    this.formGroup.controls.selectedTypes.setValue(selectedResourceTypes);
    this.selector.setValue('');
  }

  removeResourceType(resourceType: IAzureResourceType): void {
    const index = this.formGroup.value.selectedTypes.indexOf(resourceType);
    if (index === -1) {
      return;
    }

    this.formGroup.value.selectedTypes.splice(index, 1);
    this.selector.setValue('');
  }

  private _filterResourceTypes(value: string): IAzureResourceType[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const remainingElements = this.resourceTypes.filter((filterValue) => this.formGroup.value.selectedTypes.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingElements.filter((filterValue) => filterValue.displayName.toLowerCase().search(searchValue) > -1);
  }

  onOperatingSystemTypeSelected(event: any) {
    this._operatingSystemType = event.option.value as OperatingSystemType;
    this.formGroup.controls.operatingSystemType.setValue(this._operatingSystemType);
  }

  ngOnInit(): void {
    this.formGroup.controls.selectedTypes.valueChanges.subscribe((value: IAzureResourceType[]) => this.dataService.changeResourceTypes(value));
    this.dataService.automationProcessMessage.subscribe((sub) => this.onAutomationProcessChanged(sub));

    this.filteredResourceTypes = this.selector.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResourceTypes(value)),
    );
  }

  onAutomationProcessChanged(value: IAutomationProcessResponse) {
    if (!value) {
      return;
    }

    const allResourceTypes = this.automationProcessService.getResourceTypes();
    const automationProcessResourceTypes = allResourceTypes.filter((all) => value.resourceTypes.some((type) => type === all.value));

    this.resourceTypes = automationProcessResourceTypes;
    this.formGroup.value.selectedTypes = [];
  }

  public static buildResourcesSelector(): FormGroup<ResourcesSelectorForm> {
    return new FormGroup({
      selectedTypes: new FormControl<IAzureResourceType[]>([]),
      operatingSystemType: new FormControl<OperatingSystemType>(OperatingSystemType.All),
      operatingSystemTypesAvailable: new FormControl<OperatingSystemType[]>(Object.values(OperatingSystemType)),
    });
  }
}

export interface ResourcesSelectorForm {
  selectedTypes: FormControl<IAzureResourceType[]>;
  operatingSystemType: FormControl<OperatingSystemType>;
  operatingSystemTypesAvailable: FormControl<OperatingSystemType[]>;
}
