import { Component, Input, OnInit, inject, input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeploymentScopeType } from '../create-deployment-scope.model';
import { ApplicationTier } from '../../../models/enums/application-tier.enum';
import { IDigitalPlatform } from '../../../services/service-now-resources.service/service-now-resources.model';
import { ServiceNowResourcesService } from '../../../services/service-now-resources.service/service-now-resources.service';
import { Observable, map, startWith } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ResourcesScopeForm, ResourcesScopingComponent } from './resources-scoping/resources-scoping.component';
import { IDigitalPlatformScopingModel, IResourcesScopingModel } from '../../../services/deployment-scope/deployment-scope.model';
import { ManagementGroupForm, ManagementGroupsComponent } from './management-groups/management-groups.component';
import { DeploymentScopeDataService } from '../service/deployment-scope-data.service';
import { IAutomationProcessResponse } from '../../../services/automation-process/automation-process.model';
import { AutomationProcessType } from '../../../models/enums/automation-process-type.enum';

@Component({
  selector: 'scoping-type',
  templateUrl: './scoping-type.component.html',
  styleUrl: './scoping-type.component.scss',
})
export class ScopingTypeComponent implements OnInit {
  private _scopingTypeGroup: FormGroup<ScopingTypeForm>;
  private deploymentScopeDataService = inject(DeploymentScopeDataService);

  @Input({ required: true }) readOnly: boolean = false;
  @Input({ required: true }) set scopingTypeGroup(value: FormGroup<ScopingTypeForm>) {
    this._scopingTypeGroup = value;

    this.digitalPlatform = value.controls.digitalPlatform;
  }

  get scopingTypeGroup(): FormGroup<ScopingTypeForm> {
    return this._scopingTypeGroup;
  }

  digitalPlatform: FormGroup<DigitalPlatformScopeForm>;

  snowService = inject(ServiceNowResourcesService);

  scopingTypeOptions = DeploymentScopeType;
  scopingTypes: DeploymentScopeType[] = [];
  applicationTiers: ApplicationTier[] = [ApplicationTier.Tier1, ApplicationTier.Tier2, ApplicationTier.Tier3];

  digitalPlatforms: IDigitalPlatform[] = [];
  filteredDigitalPlatforms: Observable<IDigitalPlatform[]>;

  ngOnInit(): void {
    this.snowService.getDigitalPlatforms().subscribe((sub) => {
      this.digitalPlatforms = sub;

      this.filteredDigitalPlatforms = this.scopingTypeGroup.controls.digitalPlatform.controls.selector.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterDigitalPlatforms(value)),
      );
    });

    this.scopingTypeGroup.controls.scopingType.valueChanges.subscribe((sub: DeploymentScopeType) => {
      this.onScopingTypeChanged();
    });

    this.deploymentScopeDataService.automationProcessMessage.subscribe((sub) => this.onAutomationProccessChange(sub));
  }

  mapDeploymentScopeType(value: DeploymentScopeType): string {
    switch (value) {
      case DeploymentScopeType.DigitalPlatform:
        return 'By Digital Platform';
      case DeploymentScopeType.Resources:
        return 'By Resources';
      case DeploymentScopeType.ManagementGroup:
        return 'By Management Groups';
    }
  }

  private onAutomationProccessChange(value: IAutomationProcessResponse): void {
    let scopingTypes = [DeploymentScopeType.DigitalPlatform, DeploymentScopeType.Resources];

    if (value?.type === AutomationProcessType.AzurePolicy) {
      scopingTypes.push(DeploymentScopeType.ManagementGroup);
    }

    this.scopingTypes = scopingTypes;
  }

  private _filterDigitalPlatforms(value: string): IDigitalPlatform[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const remainingElements = this.digitalPlatforms.filter(
      (filterValue) => this.scopingTypeGroup.value.digitalPlatform.selectedValues.indexOf(filterValue) === -1,
    );
    const searchValue = value.toLowerCase();

    return remainingElements.filter((filterValue) => filterValue.name.toLowerCase().search(searchValue) > -1);
  }

  onScopingTypeChanged(): void {
    this.scopingTypeGroup.controls.digitalPlatform.patchValue({
      applicationTiers: [],
      selectedValues: [],
    });

    this.scopingTypeGroup.controls.resources.patchValue({
      subscriptions: [],
      resourceGroups: [],
      resources: [],
    });
  }

  onDigitalPlatformSelected(event: any): void {
    const selectedDigitalPlatform = event.option.value as IDigitalPlatform;
    let selectedValues = this.scopingTypeGroup.value.digitalPlatform.selectedValues;
    if (selectedValues.includes(selectedDigitalPlatform)) {
      return;
    } else {
      selectedValues.push(selectedDigitalPlatform);
    }

    this.scopingTypeGroup.controls.digitalPlatform.patchValue({
      selectedValues: selectedValues,
      selector: '',
    });
  }

  onRemoveDigitalPlatform(digitalPlatform: IDigitalPlatform): void {
    const index = this.digitalPlatform.value.selectedValues.indexOf(digitalPlatform);
    if (index >= 0) {
      this.digitalPlatform.value.selectedValues.splice(index, 1);
    }

    this.filteredDigitalPlatforms = this.digitalPlatform.controls.selector.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDigitalPlatforms(value)),
    );
  }

  onApplicationTierChecked(applicationTier: ApplicationTier, change: MatCheckboxChange): void {
    if (change.checked) {
      this.digitalPlatform.value.applicationTiers.push(applicationTier);
    } else {
      this.digitalPlatform.value.applicationTiers.splice(this.digitalPlatform.value.applicationTiers.indexOf(applicationTier), 1);
    }
  }

  public static buildScopingType(): FormGroup<ScopingTypeForm> {
    return new FormGroup({
      scopingType: new FormControl<DeploymentScopeType>(null, Validators.required),
      digitalPlatform: new FormGroup<DigitalPlatformScopeForm>({
        applicationTiers: new FormControl<ApplicationTier[]>([], Validators.required),
        selector: new FormControl(''),
        selectedValues: new FormControl<IDigitalPlatform[]>([], Validators.required),
      }),
      resources: ResourcesScopingComponent.buildResourcesScopingForm(),
      managementGroup: ManagementGroupsComponent.buildManagementGroupForm(),
    });
  }

  public static getScopingModels(formGroup: FormGroup<ScopingTypeForm>): [IResourcesScopingModel, IDigitalPlatformScopingModel, number[]] {
    let resourcesScope: IResourcesScopingModel;
    let digitalPlatformScope: IDigitalPlatformScopingModel;
    let managementGroupIds: number[] | null;

    const scopingType = formGroup.value.scopingType;
    if (scopingType === DeploymentScopeType.DigitalPlatform) {
      const value = formGroup.value.digitalPlatform;
      digitalPlatformScope = {
        digitalPlatformId: value.selectedValues[0].id, // will be refactored to send multiple values to backend api
        applicationTiers: value.applicationTiers,
      } as IDigitalPlatformScopingModel;
    }

    if (scopingType === DeploymentScopeType.Resources) {
      resourcesScope = ResourcesScopingComponent.getResourcesScopingModel(formGroup.controls.resources);
    }

    if (scopingType === DeploymentScopeType.ManagementGroup) {
      managementGroupIds = formGroup.value.managementGroup.managementGroups.map((x) => x.id);
    }

    return [resourcesScope, digitalPlatformScope, managementGroupIds];
  }
}

export interface ScopingTypeForm {
  scopingType: FormControl<DeploymentScopeType>;
  digitalPlatform: FormGroup<DigitalPlatformScopeForm>;
  resources: FormGroup<ResourcesScopeForm>;
  managementGroup: FormGroup<ManagementGroupForm>;
}

export interface DigitalPlatformScopeForm {
  applicationTiers: FormControl<ApplicationTier[]>;
  selector: FormControl<string>;
  selectedValues: FormControl<IDigitalPlatform[]>;
}
