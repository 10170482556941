import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
  exceptionScopingTypeFriendlyNames,
  IDeploymentExceptionSelectionForm,
} from '../../../services/deployment-exception/deployment-exception.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeploymentExceptionService } from '../../../services/deployment-exception/deployment-exception.service';
import { ExceptionScopingType } from '../../../models/enums/scoping-type.enum';

@Component({
  selector: 'exception-type-selector',
  templateUrl: './exception-type-selector.component.html'
})
export class ExceptionTypeSelectorComponent implements OnInit {
  private deploymentExceptionService = inject(DeploymentExceptionService);
  @Input({ required: false }) formGroup: FormGroup<IDeploymentExceptionSelectionForm>;
  @Input() isReadOnly: boolean;

  exceptions: ExceptionScopingType[];

  ngOnInit(): void {
    this.deploymentExceptionService.getExceptionTypes().subscribe((sub) => {
      this.exceptions = sub;

      if (!this.formGroup.get('exception').value) {
        this.formGroup.patchValue({ exception: sub[0] });
      }

      if (this.isReadOnly) {
        this.formGroup.get('exception')?.disable();
      }
    });
  }

  getExceptionFriendlyName(exception: ExceptionScopingType) {
    return exceptionScopingTypeFriendlyNames[exception];
  }

  public onSelectionChanged(option: ExceptionScopingType) {
    this.formGroup.patchValue({ exception: option });
  }

  public static buildFormGroup(): FormGroup<IDeploymentExceptionSelectionForm> {
    return new FormGroup<IDeploymentExceptionSelectionForm>({
      exception: new FormControl<ExceptionScopingType>(undefined),
    });
  }
}
