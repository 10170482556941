import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { CloudCentralRequestResponseModel, CloudCentralRequestReviewResponseModel } from './requests.model';

@Injectable({
  providedIn: 'root',
})
export class CloudCentralRequestsService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getMyRequests(): Observable<CloudCentralRequestResponseModel[]> {
    return this.httpClient.get<CloudCentralRequestResponseModel[]>(`${this.baseUrl}/requests`);
  }

  public getMyRequestsToReview(): Observable<CloudCentralRequestReviewResponseModel[]> {
    return this.httpClient.get<CloudCentralRequestReviewResponseModel[]>(`${this.baseUrl}/requests/reviews`);
  }
}
