<mat-card>
  <mat-card-content>
    <mat-card-title>Select Azure Policy</mat-card-title>
    @if (categoriesLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    } @else {
      <mat-form-field>
        <mat-label>Category</mat-label>
        <input type="text" matInput [matAutocomplete]="categories" [formControl]="azurePolicy.controls.category" />
        <mat-autocomplete #categories>
          @for (category of filteredAzurePolicyCategories | async; track category) {
            <mat-option [value]="category">{{ category === "" ? "\<empty-category\>" : category }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    }
    <mat-form-field>
      <mat-label>Built In Azure Policy</mat-label>
      <input type="text" matInput [matAutocomplete]="azurePolicies" [formControl]="azurePolicy.controls.searchText" />
      <mat-autocomplete #azurePolicies (optionSelected)="onSelectionChanged($event)" [displayWith]="displayAzurePolicySelection">
        @for (azurePolicy of azurePolicyOptions; track azurePolicy.id) {
          <mat-option [value]="azurePolicy">{{ azurePolicy.displayName }} - {{ azurePolicy.category }}</mat-option>
        }
        @if (loadingAzurePolicies) {
          <mat-option> Loading...... </mat-option>
        }
        @if (azurePolicyOptions.length === 0) {
          <mat-option [disabled]="true">No Policies Found</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    @if (initialAzurePolicyData) {
      <div>
        <json-editor [options]="jsonEditorOptions" [data]="initialAzurePolicyData" (change)="onAzurePolicyDataChange($event)" class="mt-6" #editor>
        </json-editor>
      </div>
    }
  </mat-card-content>
</mat-card>
