export enum ApprovalType {
  Application = 'Application',
  RiskManager = 'RiskManager',
  SupportGroup = 'SupportGroup',
  Platform = 'Platform',
}

export class ApprovalTypeMapper {
  static toDisplayString(approvalType: ApprovalType): string {
    switch (approvalType) {
      case ApprovalType.Application:
        return 'Application';
      case ApprovalType.RiskManager:
        return 'Risk Manager';
      case ApprovalType.SupportGroup:
        return 'Support Group';
      case ApprovalType.Platform:
        return 'Platform';
      default:
        throw new Error('Invalid ApprovalType');
    }
  }
}
