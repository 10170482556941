<mat-card>
  <mat-card-content>
    <mat-card-title>Deployment Scope Reviewers</mat-card-title>
    @if (reviewersLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    } @else {
      <table mat-table [dataSource]="reviewers">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }} ({{ element.email }}) - {{ mapOriginDisplayName(element.origin) }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="status-text">{{ element.status }} </span><mat-icon class="status-image">{{ getStatusIconName(element.status) }}</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>Comment</th>
          <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name', 'status', 'comment']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'status', 'comment']"></tr>
      </table>
    }
  </mat-card-content>
</mat-card>
