import { Component } from '@angular/core';
import { NextPageService, NavigationLocation, SearchType, PageLayout } from '@cvx/nextpage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  NavigationLocation = NavigationLocation;
  SearchType = SearchType;
  PageLayout = PageLayout;

  constructor(private NP: NextPageService) {
    this.NP.options.sitename = 'Cloud Central';
  }
}
