export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export class DayOfWeekHelper {
  static getAllDays(): DayOfWeek[] {
    return [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];
  }



  static getDayShortName(day: DayOfWeek): String {
    const shortNames = new Map([
      [DayOfWeek.Monday, "Mon"],
      [DayOfWeek.Tuesday, "Tues"],
      [DayOfWeek.Wednesday, "Wed"],
      [DayOfWeek.Thursday, "Thurs"],
      [DayOfWeek.Friday, "Fri"]
      [DayOfWeek.Saturday, "Sat"]
      [DayOfWeek.Sunday, "Sun"]
    ]);
    return shortNames[day];
  }
}
