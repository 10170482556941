<!-- Header -->
<np-header>
  <!-- Search -->
  <np-search [searchType]="SearchType.Global"></np-search>
  <!-- Global (top menu) Navigation -->
  <np-menu [location]="NavigationLocation.Global"></np-menu>
</np-header>

<!-- Site-Specific Navigation -->
<navigation-menu></navigation-menu>

<!-- Main Content -->
<div class="outlet">
  <router-outlet></router-outlet>
</div>

<!-- Footer -->
<np-footer
  [contentContactName]="'CRA Bengal Team'"
  [contentContactUrl]="'mailto:BENGAL@chevron.com'"
  [technicalContactName]="'CRA Bengal Team'"
  [technicalContactUrl]="'mailto:BENGAL@chevron.com'"
>
</np-footer>
