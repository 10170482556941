import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAzureResourceType } from '../../../models/azure-models/azure-resource-type.model';
import { IAutomationProcessResponse } from '../../../services/automation-process/automation-process.model';
import { ScopingType } from '../../../models/enums/scoping-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DeploymentScopeDataService {
  private tagsSource = new BehaviorSubject<Record<string, string>>({});
  private resourceTypesSource = new BehaviorSubject<IAzureResourceType[]>([]);
  private automationProcessSource = new BehaviorSubject<IAutomationProcessResponse>(null);
  private deploymentScopeTypesSource = new BehaviorSubject<ScopingType[]>([]);

  tagsMessage = this.tagsSource.asObservable();
  resourceTypesMessage = this.resourceTypesSource.asObservable();
  automationProcessMessage = this.automationProcessSource.asObservable();
  deploymentScopeTypes = this.deploymentScopeTypesSource.asObservable();

  changeTags(value: Record<string, string>) {
    this.tagsSource.next(value);
  }

  changeResourceTypes(value: IAzureResourceType[]) {
    this.resourceTypesSource.next(value);
  }

  changeAutomationProcess(value: IAutomationProcessResponse): void {
    this.automationProcessSource.next(value);
  }
}
