import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OptionsService } from '../../../../services/options/options.service';
import { debounceTime, map, Observable, startWith, tap } from 'rxjs';
import { BusinessApplicationScopeSearchLlistItem } from './business-application-scoping.component.model';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'business-application-scoping',
  templateUrl: './business-application-scoping.component.html',
})
export class BuisnessApplicationScopingComponent implements OnInit {
  private optionsService = inject(OptionsService);

  @ViewChild('selectedApplicationsTable') table: MatTable<BusinessApplicationScopeSearchLlistItem>;

  @Input({ required: true }) formGroup: FormGroup<BusinessApplicationScopingForm>;
  @Input({ required: true }) readOnly: boolean = false;

  businessApplicationsLoading: boolean = true;
  applications: BusinessApplicationScopeSearchLlistItem[] = [];
  filteredApplications: Observable<BusinessApplicationScopeSearchLlistItem[]>;
  selectedApplications: BusinessApplicationScopeSearchLlistItem[] = [];

  displayedColumns: string[] = ['serviceId', 'name', 'shortName', 'technicalOwner', 'removeIcon'];

  ngOnInit(): void {
    this.optionsService
      .getBusinessApplications()
      .pipe(
        tap(() => {
          this.businessApplicationsLoading = false;
        }),
      )
      .subscribe((sub) => {
        this.applications = sub.result.map((x) => {
          return {
            id: x.id,
            serviceId: x.serviceId,
            name: x.name,
            shortName: x.shortName,
            technicalOwner: x.technicalOwner,
          } as BusinessApplicationScopeSearchLlistItem;
        });

        this.filteredApplications = this.formGroup.controls.searchText.valueChanges.pipe(
          startWith(''),
          debounceTime(500), // 0.5 seconds before actual filtering starts
          map((value: string) => this.filterBusinessApplications(value)),
        );
      });
  }

  private filterBusinessApplications(value: string): BusinessApplicationScopeSearchLlistItem[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }

    const remainingApplications = this.applications.filter((filterValue) => this.selectedApplications.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingApplications.filter(
      (filterValue) =>
        filterValue.serviceId.toLowerCase().search(searchValue) > -1 ||
        filterValue.name.toLowerCase().search(searchValue) > -1 ||
        filterValue.shortName.toLowerCase().search(searchValue) > -1 ||
        filterValue.technicalOwner.toLowerCase().search(searchValue) > -1,
    );
  }

  onBusinessApplicationSelected(event: any): void {
    const selectedApplication = event.option.value as BusinessApplicationScopeSearchLlistItem;

    this.selectedApplications.push(selectedApplication);
    this.formGroup.value.selectedApplications.push(selectedApplication);

    this.formGroup.controls.searchText.setValue('');

    this.table.renderRows();
  }

  onBusinessApplicationRemoved(value: BusinessApplicationScopeSearchLlistItem): void {
    const indexToRemove = this.selectedApplications.indexOf(value);

    if (indexToRemove === -1) {
      return;
    }

    this.selectedApplications.splice(indexToRemove, 1);
    this.formGroup.value.selectedApplications.splice(indexToRemove, 1);

    this.table.renderRows();
  }

  public static buildFormGroup(): FormGroup<BusinessApplicationScopingForm> {
    return new FormGroup<BusinessApplicationScopingForm>({
      searchText: new FormControl<string>(''),
      selectedApplications: new FormControl<BusinessApplicationScopeSearchLlistItem[]>([]),
    });
  }
}

export interface BusinessApplicationScopingForm {
  searchText: FormControl<string>;
  selectedApplications: FormControl<BusinessApplicationScopeSearchLlistItem[]>;
}
