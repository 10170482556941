import { Component, OnInit } from '@angular/core';
import { PageLayout } from '@cvx/nextpage';

@Component({
  selector: 'home-view',
  templateUrl: './home.view.html',
  styleUrls: ['./home.view.scss'],
})
export class HomeView implements OnInit {
  PageLayout = PageLayout;

  ngOnInit(): void {}
}
