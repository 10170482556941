import { Component, Input, OnInit, inject } from '@angular/core';
import {
  DeploymentScopeReviewer,
  DeploymentScopeReviewerOrigin,
  DeploymentScopeReviewerStatus,
} from '../../../services/deployment-scope/deployment-scope.model';
import { DeploymentScopesService } from '../../../services/deployment-scope/deployment-scope.service';
import { tap } from 'rxjs';

@Component({
  selector: 'reviewers-list',
  templateUrl: './reviewers-list.component.html',
  styleUrl: './reviewers-list.component.scss',
})
export class ReviewersListComponent implements OnInit {
  deploymentScopesService = inject(DeploymentScopesService);

  @Input() deploymentScopeId: number;

  reviewers: DeploymentScopeReviewer[] = [];
  reviewersLoading: boolean = true;

  getStatusIconName(status: DeploymentScopeReviewerStatus): string {
    switch (status) {
      case DeploymentScopeReviewerStatus.Approved:
        return 'check';
      case DeploymentScopeReviewerStatus.Rejected:
        return 'close';
      case DeploymentScopeReviewerStatus.Pending:
        return 'schedule';
    }
    return '';
  }

  mapOriginDisplayName(value: DeploymentScopeReviewerOrigin): string {
    switch (value) {
      case DeploymentScopeReviewerOrigin.Application:
        return 'Application';
      case DeploymentScopeReviewerOrigin.ManagementGroup:
        return 'Management Group';
      case DeploymentScopeReviewerOrigin.Platform:
        return 'Platform';
      case DeploymentScopeReviewerOrigin.RiskManager:
        return 'Risk Manager';
      case DeploymentScopeReviewerOrigin.SupportGroup:
        return 'Support Group';
      default:
        return '';
    }
  }
  ngOnInit(): void {
    this.deploymentScopesService
      .getDeploymentScopeReviewers(this.deploymentScopeId)
      .pipe(tap(() => (this.reviewersLoading = false)))
      .subscribe((sub: DeploymentScopeReviewer[]) => {
        this.reviewers = sub;
      });
  }
}
