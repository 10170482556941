<main class="container" npSetLayout [pageLayout]="PageLayout.Layout12">
  <form [formGroup]="deploymentExceptionFormGroup">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Create Deployment Exception</mat-card-title>
        <span>Create exceptions here.. </span>
      </mat-card-content>
    </mat-card>

    @if (exceptionLoadSuccess$ | async) {
    <automation-process-selector [readonly]="isReadOnly" [automationProcessSelector]="deploymentExceptionFormGroup.controls.automationProcess" />

    @if(automationProcessIsSelected()){
    <ng-container>
      <business-application-selector
        [readonly]="true"
        [businessApplicationId]="businessApplicationId"
        [formGroup]="deploymentExceptionFormGroup.controls.businessAppInfo"
      />

      <exception-type-selector
        [isReadOnly]="isReadOnly"
        [formGroup]="deploymentExceptionFormGroup.controls.deploymentExcetionSelector"
      ></exception-type-selector>

      <app-date-picker
        [isReadOnly]="isReadOnly"
        [title]="'Exception Expiration Date'"
        [formGroup]="deploymentExceptionFormGroup.controls.expiresAtFormGroup"
      ></app-date-picker>

      @if(deploymentExceptionFormGroup.controls.deploymentExcetionSelector?.value.exception === exceptionScopingType.BusinessApplication){

      <business-application-scoping
        [formGroup]="deploymentExceptionFormGroup.controls.businessAppSearch"
        [readOnly]="isReadOnly"
      ></business-application-scoping>
      } @if(isReadOnly && cloudCentralRequestId){
      <reviewers-list [title]="'Exception Approvers'" [cloudCentralRequestId]="cloudCentralRequestId" />
      }
    </ng-container>
    } @if(!isReadOnly){
    <div class="form-footer button-row">
      <button
        (click)="onSubmitDeploymentException()"
        mat-raised-button
        color="primary"
        class="mr-3"
        [disabled]="isLoading || 
        isBusinessAppSearchEmpty || 
        !deploymentExceptionFormGroup.controls.expiresAtFormGroup.valid"
        type="submit"
      >
        Submit Exception
      </button>
    </div>
    } }@else {

    <mat-card>
      <mat-card-content>
        <div>Deployment exception loading failed</div>
      </mat-card-content>
    </mat-card>
    }
  </form>
</main>
