export class AzureRegionHelper {

  static getRegionShortNameByTimezone(timeZone: string): String[] {
    const shortNames = new Map([
      ["Eastern Standard Time", ["use1", "use2"]],
      ["Central Standard Time", ["usnc", "ussc", "usc1", "uswc"]],
      ["Pacific Standard Time", ["usw1", "usw2", "usw3"]],
      ["GMT Standard Time", ["eun1", "euw1", "ukw1", "uks1"]],
      ["Central Europe Standard Time", ["frc1", "frs1"]],
      ["SE Asia Standard Time", ["asse", "ase1"]],
      ["China Standard Time", ["cnn1", "cne1"]],
      ["E. Australia Standard Time", ["aue1", "ause"]],
      ["India Standard Time", ["iac1", "iaw1", "ias1"]],
      ["Tokyo Standard Time", ["jpe1", "jpw1"]],
      ["Korea Standard Time", ["krc1", "krs1"]],
      ["South Africa Standard Time", ["saw1", "san1"]],
      ["E. South America Standard Time", ["brs1", "brse"]],
      ["", []]
    ]);
    return shortNames.get(timeZone);
  }
}
