export enum ScopingType {
  ApplicationTier = 'ApplicationTier',
  DigitalPlatform = 'DigitalPlatform',
  ManagementGroup = 'ManagementGroup',
  Resources = 'Resources',
  BusinessApplication = 'BusinessApplication',
}

export enum ExceptionScopingType {
  BusinessApplication = 'BusinessApplication',
  ApplicationTier = 'ApplicationTier',
  DigitalPlatform = 'DigitalPlatform',
}
