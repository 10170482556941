import { Component, inject, Input, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OptionsService } from '../../../services/options/options.service';
import {
  BusinessApplicationDetailsForm,
  BusinessApplicationSearchListItem,
  BusinessApplicationSelectorForm,
} from './business-application-selector.component.model';
import { map, Observable, startWith, tap } from 'rxjs';

@Component({
  selector: 'business-application-selector',
  templateUrl: './business-application-selector.component.html',
})
export class BusinessApplicationSelectorComponent implements OnInit {
  private ngZone = inject(NgZone);

  optionsService = inject(OptionsService);

  optionsLoading: boolean = false;
  applicationDetailsLoading: boolean = false;
  applicationSelected: boolean = false;

  @Input({ required: false }) formGroup: FormGroup<BusinessApplicationSelectorForm>;
  @Input() readonly: boolean = false;
  @Input()
  set businessApplicationId(value: number) {
    this.onLoadBusinessApplication(value);
  }

  applications: BusinessApplicationSearchListItem[] = [];
  filteredApplications: Observable<BusinessApplicationSearchListItem[]>;

  ngOnInit(): void {
    if (!this.readonly) {
      this.ngZone.run(() => (this.optionsLoading = true));

      this.optionsService
        .getBusinessApplications()
        .pipe(tap(() => (this.optionsLoading = false)))
        .subscribe((sub) => {
          this.applications = sub.result.map((x) => {
            return {
              id: x.id,
              serviceId: x.serviceId,
              name: x.name,
              shortName: x.shortName,
              technicalOwner: x.technicalOwner,
            } as BusinessApplicationSearchListItem;
          });

          this.filteredApplications = this.formGroup.controls.searchText.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filterBusinessApplications(value)),
          );
        });
    }
  }

  onBusinessApplicationSelected(event: any): void {
    const selectedApplication = event.option.value as BusinessApplicationSearchListItem;
    this.onLoadBusinessApplication(selectedApplication.id);
  }

  onLoadBusinessApplication(businessApplicationId: number): void {
    if (!businessApplicationId) {
      return;
    }

    this.applicationDetailsLoading = true;

    this.optionsService
      .getBusinessApplicationDetails(businessApplicationId)
      .pipe(
        tap(() => {
          this.applicationSelected = true;
          this.applicationDetailsLoading = false;
          this.formGroup.controls.searchText.setValue('');
        }),
      )
      .subscribe((sub) => {
        this.formGroup.controls.application.patchValue({
          id: sub.id,
          serviceId: sub.serviceId,
          name: sub.name,
          shortName: sub.shortName,
          technicalOwner: sub.technicalOwner,
          businessCapability: sub.businessCapability,
          digitalPlatform: sub.digitalPlatform,
          supportGroup: sub.supportGroup,
        });
      });
  }

  displayEmpty(): string {
    return '';
  }

  private filterBusinessApplications(value: string): BusinessApplicationSearchListItem[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }

    const searchValue = value.toLowerCase();

    return this.applications.filter(
      (filterValue) =>
        filterValue.serviceId.toLowerCase().search(searchValue) > -1 ||
        filterValue.name.toLowerCase().search(searchValue) > -1 ||
        filterValue.shortName.toLowerCase().search(searchValue) > -1 ||
        filterValue.technicalOwner.toLowerCase().search(searchValue) > -1,
    );
  }

  public static buildFormGroup(): FormGroup<BusinessApplicationSelectorForm> {
    return new FormGroup({
      searchText: new FormControl<string>(''),
      application: new FormGroup<BusinessApplicationDetailsForm>({
        id: new FormControl<number>(0),
        serviceId: new FormControl<string>(''),
        name: new FormControl<string>(''),
        shortName: new FormControl<string>(''),
        technicalOwner: new FormControl<string>(''),
        businessCapability: new FormControl<string>(''),
        digitalPlatform: new FormControl<string>(''),
        supportGroup: new FormControl<string>(''),
      }),
    });
  }
}
