<mat-card [formGroup]="automationProcessSelector">
  <mat-card-content>
    <mat-card-title>Select Automation Process</mat-card-title>
    @if (automationProcessSelectorIsLoading) {
      <mat-spinner [diameter]="75" class="m-3"></mat-spinner>
    } @else {
      <mat-form-field>
        <mat-label>Automation Process</mat-label>
        <mat-select [formControl]="automationProcessSelector.controls.automationProcess" [compareWith]="compareFn">
          @for (automationProcess of automationProcesses; track automationProcess) {
            <mat-option [value]="automationProcess"> {{ automationProcess.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Automation Process Type</mat-label>
        <input matInput [readonly]="true" [formControl]="automationProcessSelector.controls.type" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Approval Types</mat-label>
        <input matInput [readonly]="true" [formControl]="automationProcessSelector.controls.approvalTypes" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput [readonly]="true" [formControl]="automationProcessSelector.controls.description"></textarea>
      </mat-form-field>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Business Capability</mat-label>
            <input matInput readonly [formControl]="automationProcessSelector.controls.businessCapability" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Digital Platform</mat-label>
            <input matInput readonly [formControl]="automationProcessSelector.controls.digitalPlatform" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Support Group</mat-label>
            <input matInput readonly [formControl]="automationProcessSelector.controls.supportGroup" />
          </mat-form-field>
        </div>
      </div>
    }
  </mat-card-content>
</mat-card>
