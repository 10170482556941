import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { AzureService } from '../../../services/azure-service.service/azure-service.service';
import { Observable, debounceTime, map, startWith, tap } from 'rxjs';
import { AzurePolicySearchModel } from '../../../services/azure-service.service/azure-service.model';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'azure-policy-editor',
  templateUrl: './azure-policy-editor.component.html',
  styleUrls: ['./azure-policy-editor.component.scss'],
})
export class AzurePolicyEditorComponent implements OnInit {
  azureService = inject(AzureService);
  @ViewChild('azurePolicies') policiesAutocomplete: MatAutocomplete;

  azurePolicy = new FormGroup({
    category: new FormControl<string>(''),
    searchText: new FormControl<string>(''),
  });

  azurePolicyCategories: string[] = [];
  filteredAzurePolicyCategories: Observable<string[]>;
  categoriesLoading: boolean = true;

  azurePolicyOptions: AzurePolicySearchModel[] = [];
  loadingAzurePolicies: boolean = false;
  azurePoliciesPage = 1;
  allPoliciesLoaded: boolean = false;

  jsonEditorOptions: JsonEditorOptions;

  // two different variables are needed because if change initial variable, component is rerendered and cursor focus is reseted
  initialAzurePolicyData: any;
  finalAzurePolicyData: any;

  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  constructor() {
    this.jsonEditorOptions = new JsonEditorOptions();
    this.jsonEditorOptions.modes = ['code'];
    this.jsonEditorOptions.mode = 'code';
  }

  private filterAzurePolicyCategories(value: string): string[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    let searchValue = value.toLowerCase();
    searchValue = this.escapeRegExCharacters(searchValue);

    return this.azurePolicyCategories.filter((filterValue) => filterValue.toLowerCase().search(searchValue) > -1);
  }

  private escapeRegExCharacters(value: string): string {
    return value.replace(/[^A-Za-z0-9_]/g, '\\$&');
  }

  getAzurePolicies(filter: string, page: number): Observable<AzurePolicySearchModel[]> {
    const category = this.azurePolicy.value.category;

    return this.azureService.getAzurePolicies(category, filter, page).pipe(map((x) => x.result));
  }

  ngAfterViewInit() {
    this.setupScrollListener();
  }

  setupScrollListener(): void {
    this.policiesAutocomplete.opened.subscribe(() => this.registerPanelScrollEvent());
  }

  registerPanelScrollEvent(): void {
    setTimeout(() => {
      const panel = this.policiesAutocomplete.panel;

      // Remove any listeners that might have been added before
      panel.nativeElement.removeEventListener('scroll', (event: any) => this.onScroll(event));

      if (panel) {
        panel.nativeElement.addEventListener('scroll', (event: any) => this.onScroll(event));
      }
    });
  }

  onScroll(event: any): void {
    if (!this.scrollReachedBottom(event) || this.loadingAzurePolicies || this.allPoliciesLoaded) {
      return;
    }

    this.loadingAzurePolicies = true;
    this.azurePoliciesPage += 1;

    setTimeout(() => {
      this.azureService
        .getAzurePolicies(this.azurePolicy.value.category, this.azurePolicy.value.searchText, this.azurePoliciesPage)
        .pipe(tap(() => (this.loadingAzurePolicies = false)))
        .subscribe((sub) => {
          this.azurePolicyOptions = this.azurePolicyOptions.concat(sub.result);

          if (sub.result.length === 0) {
            this.allPoliciesLoaded = true;
          }
        });
    }, 1_500);
  }

  scrollReachedBottom(event: any): boolean {
    return event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight;
  }

  ngOnInit(): void {
    this.azureService
      .getPolicyCategories()
      .pipe(tap(() => (this.categoriesLoading = false)))
      .subscribe((sub) => {
        this.azurePolicyCategories = sub.map((x) => x.name);

        this.filteredAzurePolicyCategories = this.azurePolicy.controls.category.valueChanges.pipe(
          startWith(''),
          map((value: string) => this.filterAzurePolicyCategories(value || '')),
        );
      });

    this.azureService.getAzurePolicies().subscribe((x) => {
      this.azurePolicyOptions = x.result;
    });

    this.azurePolicy.controls.category.valueChanges.subscribe((sub) => {
      this.azurePolicy.controls.searchText.setValue(null);
      this.initialAzurePolicyData = null;
      this.finalAzurePolicyData = null;

      this.azurePoliciesPage = 1;

      this.azureService.getAzurePolicies(sub, null, this.azurePoliciesPage).subscribe((x) => {
        this.azurePolicyOptions = x.result;
      });
    });

    this.azurePolicy.controls.searchText.valueChanges.pipe(debounceTime(500)).subscribe((sub) => {
      this.azureService.getAzurePolicies(this.azurePolicy.value.category, sub, 1).subscribe((x) => {
        this.azurePolicyOptions = x.result;
      });
    });
  }

  onAzurePolicyDataChange(event: any) {
    this.finalAzurePolicyData = event;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    const selection = event.option.value as AzurePolicySearchModel;

    this.azureService.getAzurePolicy(selection.id).subscribe((sub) => {
      this.initialAzurePolicyData = sub;
    });
  }

  displayAzurePolicySelection(value: AzurePolicySearchModel): string {
    if (!value) {
      return null;
    }

    return `${value.displayName} - ${value.category}`;
  }
}
