import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, mergeWith } from 'rxjs';
import { IBusinessCapability, IDigitalPlatform, IGetBusinessCapabilitiesResponse, IGetDigitalPlatformsResponse } from './service-now-resources.model';
import { AppConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceNowResourcesService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getDigitalPlatforms(): Observable<IDigitalPlatform[]> {
    return this.httpClient
      .get<IGetDigitalPlatformsResponse>(`${this.baseUrl}/service-now/digital-platforms`)
      .pipe(map((data: IGetDigitalPlatformsResponse) => data.options));
  }

  public getBusinessCapabilities(): Observable<IBusinessCapability[]> {
    const url = new URL(`${this.baseUrl}/service-now/business-capabilities`);

    let params = new URLSearchParams();
    params.append('top', '5000');
    params.append('skip', '0');

    url.search = params.toString();

    return this.httpClient.get<IGetBusinessCapabilitiesResponse>(url.toString()).pipe(map((data: IGetBusinessCapabilitiesResponse) => data.options));
  }
}
