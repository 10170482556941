import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PageLayout } from '@cvx/nextpage';
import { AutomationProcessSelectorComponent } from '../create-deployment-scope/automation-process-selector/automation-process-selector.component';
import { BusinessApplicationSelectorComponent } from '../create-automation-process/business-application-selector/business-application-selector.component';
import { DeploymentScopeDataService } from '../create-deployment-scope/service/deployment-scope-data.service';
import { ExceptionTypeSelectorComponent } from './exception-type-selector/exception-type-selector.component';
import { DeploymentExceptionService } from '../../services/deployment-exception/deployment-exception.service';
import {
  IDeploymentExceptionRequestModel,
  IExpiresAtExceptionDateTimeFormGroup,
  IGetDeploymentExceptionResponseModel,
} from '../../services/deployment-exception/deployment-exception.model';
import { ToastService } from '../../services/toast/toast.service';
import { BehaviorSubject, catchError, debounceTime, finalize, map, throwError } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IAutomationProcessResponse } from '../../services/automation-process/automation-process.model';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { ExceptionScopingType } from '../../models/enums/scoping-type.enum';
import { BuisnessApplicationScopingComponent } from '../create-deployment-scope/scoping-type/business-application-scoping/business-application-scoping.component';
import { OptionsService } from '../../services/options/options.service';
import { BusinessApplicationScopeSearchLlistItem } from '../create-deployment-scope/scoping-type/business-application-scoping/business-application-scoping.component.model';
import { BusinessApplicationResponseModel } from '../../services/options/options.model';
import { DatePickerComponent } from '../../components/date-picker/date-picker.component';
import { AutomationProcessService } from '../../services/automation-process/automation-process.service';
import { CloudCentralRequestsService } from '../../services/requests/requests.service';

@Component({
  selector: 'app-create-deployment-exception',
  templateUrl: './create-deployment-exception.view.html',
  styleUrl: './create-deployment-exception.view.scss',
})
export class CreateDeploymentExceptionView implements OnInit {
  private deploymentDataScopeService = inject(DeploymentScopeDataService);
  private deploymentExceptionService = inject(DeploymentExceptionService);
  private toastService = inject(ToastService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authService = inject(CalAngularService);
  private optionsService = inject(OptionsService);
  private automationProcessService = inject(AutomationProcessService);
  private cloudCentralRequestsService = inject(CloudCentralRequestsService);

  exceptionScopingType = ExceptionScopingType;

  currentUserProfile: ICvxClaimsPrincipal;
  deploymentExceptionId?: number;
  isReadOnly: boolean;
  PageLayout = PageLayout;
  businessApplicationId?: number;
  deploymentScopeId?: number;
  cloudCentralRequestId?: number;
  isLoading: boolean;
  exceptionLoadSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  deploymentExceptionFormGroup = new FormGroup({
    automationProcess: AutomationProcessSelectorComponent.buildAutomationProcessSelectorForm(),
    businessAppInfo: BusinessApplicationSelectorComponent.buildFormGroup(),
    deploymentExcetionSelector: ExceptionTypeSelectorComponent.buildFormGroup(),
    businessAppSearch: BuisnessApplicationScopingComponent.buildFormGroup(),
    expiresAtFormGroup: DatePickerComponent.buildExpiresAtFormGroup(this.setDatePickerDefault(), setDatePickerValidator()),
  });

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.subscribeToAuthService();
    this.subscribeToSelectedApplicationChange();
    this.subscribeToDeploymentExcetionSelector();
  }

  private subscribeToRouteParams(): void {
    this.route.params.subscribe((params: Params) => {
      const deploymentExceptionId = params['deploymentExceptionId'];
      if (deploymentExceptionId) {
        this.handleDeploymentExceptionId(deploymentExceptionId);
      } else {
        this.subscribeToAutomationProcessMessage();
      }
    });
  }

  private handleDeploymentExceptionId(deploymentExceptionId: number): void {
    this.deploymentExceptionId = deploymentExceptionId;

    this.deploymentExceptionService
      .getDeploymentException(deploymentExceptionId)
      .pipe(catchError(() => [this.exceptionLoadSuccess$.next(false)]))
      .subscribe((sub: IGetDeploymentExceptionResponseModel) => {
        if (!sub) {
          return;
        }

        this.deploymentScopeId = sub.automationProcessId;
        this.cloudCentralRequestId = sub.cloudCentralRequestId;

        this.deploymentExceptionFormGroup.controls.expiresAtFormGroup.patchValue({ date: sub.expiresAt });
        this.deploymentExceptionFormGroup.controls.deploymentExcetionSelector.patchValue({ exception: sub.exceptionScoping });

        this.setSelectedBussinesApplication(sub.businessApplicationId);
        this.automationProcessService.getAutomationProcess(sub.automationProcessId).subscribe((sub) => {
          this.initializeFormValues(sub);
          this.subscribeToAutomationProcessMessage();
        });
      });

    this.isReadOnly = true;
  }

  setSelectedBussinesApplication(businessApplicationId: number) {
    if (!businessApplicationId) {
      return;
    }

    this.optionsService.getBusinessApplicationDetails(businessApplicationId).subscribe((sub) => {
      const app: BusinessApplicationResponseModel = {
        id: sub.id,
        name: sub.name,
        serviceId: sub.serviceId,
        shortName: sub.shortName,
        technicalOwner: sub.technicalOwner,
      };

      this.setSelectedApplications([app]);
    });
  }

  private subscribeToSelectedApplicationChange() {
    this.deploymentExceptionFormGroup.controls.businessAppSearch.valueChanges.subscribe((value) => {
      if (value.selectedApplications.length === 0) {
        return;
      }

      if (value.selectedApplications.length === 1) {
        this.setSelectedApplications(value.selectedApplications);
      } else {
        var items = value.selectedApplications[value.selectedApplications.length - 1];
        this.setSelectedApplications([items]);
      }
    });
  }

  private setSelectedApplications(items: BusinessApplicationScopeSearchLlistItem[]) {
    this.deploymentExceptionFormGroup.controls.businessAppSearch.patchValue({ selectedApplications: items }, { emitEvent: false });
  }

  private subscribeToAutomationProcessMessage(): void {
    this.deploymentDataScopeService.automationProcessMessage.subscribe((sub) => this.onAutomationProcessChanged(sub));
  }

  private subscribeToAuthService(): void {
    this.authService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      }
    });
  }

  private initializeFormValues(automationProces: IAutomationProcessResponse): void {
    this.businessApplicationId = automationProces.businessApplicationId;

    const automationProcessValue = {
      id: automationProces.id,
      name: automationProces.name,
      description: automationProces.description,
      resourceTypes: [],
      approvalTypes: automationProces.approvalTypes,
      category: automationProces.category,
      scopingTypes: automationProces.scopingTypes,
    } as IAutomationProcessResponse;

    this.deploymentExceptionFormGroup.controls.automationProcess.patchValue({
      automationProcess: automationProcessValue,
      description: automationProces.description,
      category: automationProces.category,
      approvalTypes: automationProces.approvalTypes.join(', '),
    });
  }

  automationProcessIsSelected(): boolean {
    return !!this.deploymentExceptionFormGroup.getRawValue().automationProcess.automationProcess;
  }

  onAutomationProcessChanged(value: any): void {
    this.isLoading = true;

    if (!value) {
      return;
    }

    this.businessApplicationId = value.businessApplicationId;
    this.deploymentScopeId = value.id;

    this.isLoading = false;
  }

  onSubmitDeploymentException(): void {
    this.isLoading = true;
    const rawForm = this.deploymentExceptionFormGroup.getRawValue();

    const request: IDeploymentExceptionRequestModel = {
      automationProcessId: rawForm.automationProcess.automationProcess.id,
      exceptionScoping: rawForm.deploymentExcetionSelector.exception,
      businessApplicationId: rawForm.businessAppSearch.selectedApplications[0].id,
      expiratesAt: rawForm.expiresAtFormGroup.date,
    };

    this.deploymentExceptionService
      .createDeploymentException(request)
      .pipe(
        catchError((error) => {
          this.toastService.showError('Failed to create deployment exception.');
          return throwError(() => error);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((sub) => {
        this.toastService.showSuccess('Deployment exception has been created!.');
        setTimeout(() => this.router.navigate([`/deployment-exception/${sub.deploymentExceptionId}`]), 3_000);
      });
  }

  onExceptionReject(): void {
    this.cloudCentralRequestsService.rejectRequest(this.currentUserProfile.userName, this.cloudCentralRequestId);
  }

  onExceptionApprove(): void {
    this.cloudCentralRequestsService.approveRequest(this.currentUserProfile.userName, this.cloudCentralRequestId);
  }

  subscribeToDeploymentExcetionSelector() {
    this.deploymentExceptionFormGroup.controls.deploymentExcetionSelector.valueChanges.subscribe(() => {
      this.setSelectedApplications([]);
    });
  }

  setDatePickerDefault(): Date {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
  }

  get isBusinessAppSearchEmpty(): boolean {
    return this.deploymentExceptionFormGroup.controls.businessAppSearch.controls.selectedApplications.value.length === 0;
  }
}

export function setDatePickerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const today = new Date();
    const oneYearFromToday = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

    const selectedDate = new Date(control.value);

    if (selectedDate < today) {
      return { message: 'Date cannot be in the past' };
    } else if (selectedDate > oneYearFromToday) {
      return { message: 'Date cannot be later than one year from today' };
    }

    return null;
  };
}
