<mat-card [formGroup]="automationProcessSelector">
  <mat-card-content>
    <mat-card-title>Select Automation Process</mat-card-title>
    @if (automationProcessSelectorIsLoading) {
      <mat-spinner [diameter]="75" class="m-3"></mat-spinner>
    } @else {
      <mat-form-field>
        <mat-label>Automation Process</mat-label>
        <mat-select
          [formControl]="automationProcessSelector.controls.automationProcess"
          [compareWith]="compareFn"
          (selectionChange)="onAutomationProcessChanged($event)"
        >
          @for (automationProcess of automationProcesses; track automationProcess) {
            <mat-option [value]="automationProcess"> {{ automationProcess.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Category</mat-label>
        <input matInput [readonly]="true" [formControl]="automationProcessSelector.controls.category" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Approval Types</mat-label>
        <input matInput [readonly]="true" [formControl]="automationProcessSelector.controls.approvalTypes" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput [readonly]="true" [formControl]="automationProcessSelector.controls.description"></textarea>
      </mat-form-field>
    }
  </mat-card-content>
</mat-card>
