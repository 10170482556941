import { Component, OnInit, inject } from '@angular/core';
import { PageLayout } from '@cvx/nextpage';
import { CloudCentralRequestsService } from '../../services/requests/requests.service';
import { tap } from 'rxjs';
import { CloudCentralRequestListItem, CloudCentralRequestReviewListItem } from './my-profile.view.model';

@Component({
  selector: 'my-profile-view',
  templateUrl: './my-profile.view.html',
  styleUrls: ['./my-profile.view.scss'],
})
export class MyProfileView implements OnInit {
  requestsService = inject(CloudCentralRequestsService);
  PageLayout = PageLayout;

  cloudCentralRequests: CloudCentralRequestListItem[] = [];
  cloudCentralRequestsForReview: CloudCentralRequestReviewListItem[];

  cloudCentralRequestsLoading: boolean = true;
  cloudCentralRequestsForReviewLoading: boolean = true;

  ngOnInit(): void {
    this.requestsService
      .getMyRequests()
      .pipe(tap(() => (this.cloudCentralRequestsLoading = false)))
      .subscribe((sub) => {
        this.cloudCentralRequests = sub.map((x) => {
          return {
            id: x.deploymentScopeId.toString(),
            type: 'Deployment Scope',
            status: x.status,
            createdAt: new Date(x.createdAt),
            updatedAt: new Date(x.updatedAt),
          } as CloudCentralRequestListItem;
        });
      });

    this.requestsService
      .getMyRequestsToReview()
      .pipe(tap(() => (this.cloudCentralRequestsForReviewLoading = false)))
      .subscribe((sub) => {
        this.cloudCentralRequestsForReview = sub.map((x) => {
          return {
            id: x.deploymentScopeId,
            type: 'Deployment Scope',
            requestStatus: x.requestStatus,
            reviewStatus: x.reviewStatus,
            createdAt: new Date(x.createdAt),
            updatedAt: new Date(x.updatedAt),
          } as CloudCentralRequestReviewListItem;
        });
      });
  }

  formatDate(value: Date): string {
    return value.toUTCString();
  }
}
