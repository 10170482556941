<mat-card>
  <mat-card-content>
    <mat-card-title>{{ title }}</mat-card-title>
    <form [formGroup]="formGroup">
      <mat-form-field class="example-full-width">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="formGroup.controls.date" required [readonly]="isReadOnly" />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="isReadOnly">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        @if (formGroup.controls.date?.hasError("required")) {
          <mat-error> Date is required </mat-error>
        }

        @if (formGroup.controls.date?.hasError("message")) {
          <mat-error> {{ formGroup.controls.date?.errors["message"] }} </mat-error>
        }
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
