<mat-card>
  <mat-card-content>
    <mat-card-title>Select Scoping Type</mat-card-title>
    <div>
      <mat-radio-group [formControl]="scopingTypeGroup.controls.scopingType" class="scoping-type">
        @for (scopingType of scopingTypes; track scopingType) {
          <mat-radio-button [value]="scopingType">{{ mapDeploymentScopeType(scopingType) }}</mat-radio-button>
        }
      </mat-radio-group>
    </div>
  </mat-card-content>
</mat-card>
@if (scopingTypeGroup.value.scopingType == scopingTypeOptions.DigitalPlatform) {
  <mat-card>
    <mat-card-content>
      <mat-card-title>Select Deployment Scope for Digital Platforms</mat-card-title>
      <div>
        <mat-form-field>
          <mat-label>Digital Platforms</mat-label>
          <input matInput type="text" [matAutocomplete]="digitalPlatformsAutocomplete" [formControl]="digitalPlatform.controls.selector" />
          <mat-autocomplete #digitalPlatformsAutocomplete="matAutocomplete" (optionSelected)="onDigitalPlatformSelected($event)">
            @for (digitalPlatform of filteredDigitalPlatforms | async; track digitalPlatform.name) {
              <mat-option [value]="digitalPlatform">{{ digitalPlatform.name }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-listbox>
          @for (digitalPlatform of digitalPlatform.value.selectedValues; track digitalPlatform.name) {
            <mat-chip (removed)="onRemoveDigitalPlatform(digitalPlatform)" [disabled]="readOnly">
              {{ digitalPlatform.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          }
        </mat-chip-listbox>
      </div>
      <section class="application-tiers mt-4">
        <label>Application Tiers</label>
        @for (applicationTier of applicationTiers; track applicationTier) {
          <mat-checkbox
            [checked]="digitalPlatform.value.applicationTiers.includes(applicationTier)"
            [value]="applicationTier"
            [disabled]="readOnly"
            (change)="onApplicationTierChecked(applicationTier, $event)"
          >
            {{ applicationTier }}
          </mat-checkbox>
        }
      </section>
    </mat-card-content>
  </mat-card>
}
@if (scopingTypeGroup.value.scopingType === scopingTypeOptions.Resources) {
  <resources-scoping [formGroup]="scopingTypeGroup.controls.resources" [readonly]="readOnly"></resources-scoping>
}
@if (scopingTypeGroup.value.scopingType === scopingTypeOptions.ManagementGroup) {
  <management-groups [readonly]="readOnly" [formGroup]="scopingTypeGroup.controls.managementGroup"></management-groups>
}
