<div class="container">
  <nav id="site-specific-nav">
    <ul class="nav-items">
      <li>
        <a routerLink="/" class="inactiveLink">Compliance</a>
        <ul>
          <li>
            <a routerLink="/automation-process">Create Automation Process</a>
          </li>
          <li>
            <a routerLink="/deployment-scopes">Create Deployment Scope</a>
          </li>
          <li>
            <a routerLink="/deployment-exception">Create Deployment Exception</a>
          </li>
        </ul>
      </li>
      <li>
        <a routerLink="/" class="inactiveLink">Compute Management</a>
      </li>
      <li>
        <a routerLink="/" class="inactiveLink">FinOps</a>
      </li>
      <li>
        <a routerLink="/" class="inactiveLink">Support</a>
      </li>
      <li>
        <a routerLink="/my-profile">My Profile</a>
      </li>
    </ul>
  </nav>
</div>
