export enum AutomationProcessType {
  CloudFeature = 'CloudFeature',
  AzurePolicy = 'AzurePolicy',
}

export class AutomationProcessTypeMapper {
  static toDisplayString(type: AutomationProcessType): string {
    switch (type) {
      case AutomationProcessType.CloudFeature:
        return 'Cloud Feature';
      case AutomationProcessType.AzurePolicy:
        return 'Azure Policy';
      default:
        throw new Error('Invalid AutomationProcessType');
    }
  }
}
