<main class="container pt-0" npSetLayout [pageLayout]="PageLayout.Layout363">
  <section id="dummy-column1">
    <div class="row p-3 m-0">
      <div class="col">
        <h3>New Automation Processes</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit nibh id nisi elementum, vel commodo augue
          egestas. Integer finibus erat metus, vitae mattis nisi suscipit nec. Morbi purus augue, vulputate at purus eget,
          vestibulum feugiat mi. Nunc cursus ut augue et laoreet. Ut mattis congue viverra. Etiam ornare ullamcorper.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit nibh id nisi elementum, vel commodo augue
          egestas. Integer finibus erat metus, vitae mattis nisi suscipit nec. Morbi purus augue, vulputate at purus eget,
          vestibulum feugiat mi. Nunc cursus ut augue et laoreet. Ut mattis congue viverra. Etiam ornare ullamcorper.
        </p>
      </div>
    </div>
  </section>
  <section id="welcome-column">
    <div class="row p-3 m-0">
      <div class="col">
        <h3>Welcome to cloud central!</h3>
        <div class="p-3">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pretium tempus nulla. Quisque at efficitur turpis.
            Duis iaculis accumsan leo, in lacinia dui mollis at. Nullam tincidunt vulputate porttitor. Morbi commodo ipsum non
            gravida ultricies. Donec mauris magna, tincidunt ac condimentum non, tincidunt sit amet purus. Pellentesque posuere
            quam metus, et tristique tortor mollis ac. Nullam tristique ut ex eget viverra. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Integer aliquam sapien eu lacinia consectetur.
          </p>
          <p>
            In quis tincidunt magna. Donec commodo ipsum eget orci tempor aliquam. Vivamus tempor a leo sollicitudin mollis. Cras
            molestie eu sapien a fermentum. Maecenas tristique mauris at purus ultrices, vitae vestibulum augue congue. Duis lacus
            ex, gravida id mi pellentesque, pharetra hendrerit dui. Suspendisse non lorem nec tortor pellentesque egestas. Sed
            placerat et nunc eget imperdiet. Proin sed lobortis felis. Sed ultrices eros lobortis, faucibus lorem vulputate,
            commodo nisl. Quisque et venenatis lorem, non ultricies magna. Nunc consequat, velit ac tincidunt commodo, enim lorem
            convallis urna, non posuere lorem tellus ut est. Praesent convallis placerat tellus sed ultricies.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="row p-3 m-0">
      <div class="col">
        <h3>Compliance by platform</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit nibh id nisi elementum, vel commodo augue
          egestas. Integer finibus erat metus, vitae mattis nisi suscipit nec. Morbi purus augue, vulputate at purus eget,
          vestibulum feugiat mi. Nunc cursus ut augue et laoreet. Ut mattis congue viverra. Etiam ornare ullamcorper.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit nibh id nisi elementum, vel commodo augue
          egestas. Integer finibus erat metus, vitae mattis nisi suscipit nec. Morbi purus augue, vulputate at purus eget,
          vestibulum feugiat mi. Nunc cursus ut augue et laoreet. Ut mattis congue viverra. Etiam ornare ullamcorper.
        </p>
      </div>
    </div>
  </section>
</main>
