import { FormControl } from '@angular/forms';
import { ExceptionScopingType } from '../../models/enums/scoping-type.enum';

export interface IDeploymentExceptionRequestModel {
  automationProcessId: number;
  exceptionScoping: ExceptionScopingType;
  businessApplicationId: number;
  expiratesAt: Date;
}

export interface IDeploymentExceptionResponseModel {
  deploymentExceptionId: number;
  exceptionScoping: ExceptionScopingType;
  businessApplicationId: number;
  expiratesAt: Date;
}

export interface IGetDeploymentExceptionResponseModel {
  automationProcessId: number;
  deploymentExceptionId: number;
  exceptionScoping: ExceptionScopingType;
  businessApplicationId: number;
  expiresAt: Date;
  cloudCentralRequestId? : number;
}

export interface IDeploymentExceptionSelectionForm {
  exception: FormControl<ExceptionScopingType>;
}

export interface IExpiresAtExceptionDateTimeFormGroup {
  date: FormControl<Date>;
}

export const exceptionScopingTypeFriendlyNames: { [key in ExceptionScopingType]: string } = {
  [ExceptionScopingType.BusinessApplication]: 'Business Application',
  [ExceptionScopingType.DigitalPlatform]: 'Digital Platform',
  [ExceptionScopingType.ApplicationTier]: 'Application Tier',
};

