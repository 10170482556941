import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  AzurePolicyCategoriesResponse,
  AzurePolicyCategory,
  GetAzurePoliciesResponse,
  GetManagementGroupDescendantsResponse,
  GetManagementGroupsResponse,
  IAzureResource,
  IAzureResourceGroup,
  IAzureResourceGroupResponse,
  IAzureResourcesResponse,
  IAzureSubscription,
  IAzureSubscriptionsResponse,
  ManagementGroupResponseModel,
  ManagementGroupTreeNode,
} from './azure-service.model';
import { Observable, map } from 'rxjs';
import { AppConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class AzureService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getResources(
    subscriptionId: string,
    resourceGroupName: string,
    resourceTypes?: string[],
    tags?: Record<string, string>,
  ): Observable<IAzureResource[]> {
    let queryParams = new HttpParams();

    if (tags) {
      queryParams = queryParams.append('tags', JSON.stringify(tags));
    }

    resourceTypes.forEach((type: string) => {
      queryParams = queryParams.append('resourceTypes', type);
    });

    return this.httpClient
      .get<IAzureResourcesResponse>(`${this.baseUrl}/azure/subscriptions/${subscriptionId}/resource-groups/${resourceGroupName}/resources`, {
        params: queryParams,
      })
      .pipe(map((data: IAzureResourcesResponse) => data.options));
  }

  public getResourceGroups(subscriptionId: string, tags?: Record<string, string>): Observable<IAzureResourceGroup[]> {
    let queryParams = new HttpParams();

    if (tags) {
      queryParams = queryParams.append('tags', JSON.stringify(tags));
    }

    return this.httpClient
      .get<IAzureResourceGroupResponse>(`${this.baseUrl}/azure/subscriptions/${subscriptionId}/resource-groups`, {
        params: queryParams,
      })
      .pipe(map((data: IAzureResourceGroupResponse) => data.options));
  }

  public getSubscriptions(): Observable<IAzureSubscription[]> {
    return this.httpClient
      .get<IAzureSubscriptionsResponse>(`${this.baseUrl}/azure/subscriptions`)
      .pipe(map((data: IAzureSubscriptionsResponse) => data.options));
  }

  public getAzurePolicies(category?: string, searchText?: string, page?: number): Observable<GetAzurePoliciesResponse> {
    let queryParams = new HttpParams();

    if (category) {
      queryParams = queryParams.append('category', category);
    }

    if (searchText) {
      queryParams = queryParams.append('searchText', searchText);
    }

    if (page) {
      queryParams = queryParams.append('page', page);
    }

    return this.httpClient.get<GetAzurePoliciesResponse>(`${this.baseUrl}/azure/policies`, { params: queryParams });
  }

  public getAzurePolicy(id: string): Observable<Object> {
    return this.httpClient.get(`${this.baseUrl}/azure/policies/${id}`);
  }

  public getPolicyCategories(): Observable<AzurePolicyCategory[]> {
    return this.httpClient
      .get<AzurePolicyCategoriesResponse>(`${this.baseUrl}/azure/policies/categories`)
      .pipe(map((data: AzurePolicyCategoriesResponse) => data.categories));
  }

  public getManagementGroups(): Observable<ManagementGroupResponseModel[]> {
    return this.httpClient
      .get<GetManagementGroupsResponse>(`${this.baseUrl}/azure/management-groups`)
      .pipe(map((data: GetManagementGroupsResponse) => data.result));
  }

  public getManagementGroupDescendantsTree(managementGroupId: string): Observable<ManagementGroupTreeNode> {
    return this.httpClient
      .get<GetManagementGroupDescendantsResponse>(`${this.baseUrl}/azure/management-groups/${managementGroupId}/descendants`)
      .pipe(map((data) => data.rootNode));
  }
}
