import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, concatMap, throwError } from 'rxjs';
import { AppConfigService } from '../config/config.service';
import { CalAngularService } from '@cvx/cal-angular';
import { ToastService } from '../toast/toast.service';
import { ErrorMapper } from './interceptor.model';

@Injectable({
  providedIn: 'root',
})
export class CalAuthorizationInterceptor implements HttpInterceptor {
  config = inject(AppConfigService);
  authService = inject(CalAngularService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAADToken(this.config.oidcScopes).pipe(
      concatMap((token) => {
        const httpHeaders = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const request = req.clone({ headers: httpHeaders });
        return next.handle(request);
      }),
    );
  }
}

@Injectable()
export class ErrorCodeInterceptor implements HttpInterceptor {
  toastService = inject(ToastService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.ErrorCode) {
          let message = ErrorMapper.getErrorMessage(httpErrorResponse.error.ErrorCode);

          if (!message) {
            message = 'Operation has failed.';
          }

          this.toastService.showError(message);
        }

        return throwError(() => httpErrorResponse);
      }),
    );
  }
}
